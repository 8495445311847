import React from "react";
import Radium, { StyleRoot } from "radium";
import Tick from "../../../assets/images/littleTick.png";

const acceptedIcon = (props) => {
  return (
    <StyleRoot>
      <span data-text="Accepted" className="toolTip" onClick={props.click}>
        <img className="icons" src={Tick} alt="delete" />
      </span>
    </StyleRoot>
  );
};

export default Radium(acceptedIcon);
