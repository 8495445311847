import React from "react";
import Radium, { StyleRoot } from "radium";

import DonateBtn from "../../../assets/images/footerDonate.png";

const Donate = (props) => {
  return (
    <StyleRoot>
      <section
        id="Donate"
        className="section"
        style={{
          height: "90vh",
          "@media (max-width: 770px)": {
            height: "fit-content",
          },
        }}>
        <div
          className="textContainer"
          style={{
            display: "flex",
            alignItems: "center",
            "@media (max-width: 770px)": {
              flexFlow: "column",
              minWidth: "90%",
            },
          }}>
          <h2
            style={{
              "@media (max-width: 770px)": {
                margin: "auto",
              },
            }}
            className="headings">
            Donate to <span className="spanRed">Peicst</span>{" "}
          </h2>
          <div
            className="text"
            style={{
              marginLeft: "2rem",
              "@media (max-width: 770px)": {
                margin: "auto",
              },
            }}>
            <span className="tagLine">
              Peicst aims to encourage viewing wealth as tangible value.
            </span>
            <br></br> <br></br>
            Peicst hopes, this platform and the resources provided within
            documents and features promote and support the distribution of
            wealth by providing access to resources that may otherwise be
            inaccessible. <br />
            We aim to promote equal opportunities within our communities and
            help reduce the gap between wealth and poverty by encouraging people
            to trade and barter skills, goods and services they believe have a
            tangible value. <br />
            <br />
            We are continuously thinking of ways to grow, improve and reach more
            people. We hope you will support our mission and consider making a
            donation to help us grow!
          </div>
        </div>
        <form
          style={{
            position: "absolute",
            bottom: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow: "column",
            "@media (max-width: 770px)": {
              position: "static",
            },
          }}
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top">
          <small
            style={{
              marginRight: "1.5rem",
              "@media (max-width: 770px)": {
                margin: "auto",
              },
            }}
            className="tagLine">
            Thank you
          </small>
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="2KND8U4SNZ9M4" />
          <input
            style={{ width: "8rem", margin: "10px" }}
            type="image"
            src={DonateBtn}
            border="0"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
        </form>
      </section>
    </StyleRoot>
  );
};
export default Radium(Donate);
