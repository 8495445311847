import React, { useState } from "react";
import Radium, { StyleRoot } from "radium";

import IconWithToolTip from "../../../UI/icons/iconWithToolTip";
import CloseIcon from "../../../../assets/images/bin.png";
import Arrow from "../../../../assets/images/arrow.png";
import Minus from "../../../../assets/images/minus.png";
const NDADownloadWidget = (props) => {
  const [showTabContent, setShowTabContent] = useState({
    "Barter Agreement": false,
    "Online Business and Consumer Goods Services": false,
    "Confidentiality Agreement": false,
  });

  const switchTabContent = (tab) => {
    const newState = {};
    Object.entries(showTabContent).forEach(([key, val]) => {
      if (key !== tab) return (newState[key] = false);
    });
    setShowTabContent({ [tab]: !showTabContent[tab], ...newState });
  };
  const style = {
    widgetContainer: {
      position: "absolute",
      top: "15%",
      left: "5px",
      zIndex: "700",
      width: "245px",
      height: "fit-content",
      backgroundColor: "white",
      borderRadius: ".5rem",
      padding: "10px",
    },
    tab: {
      maxWidth: "95%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0px 10px 10px 0px rgba(50, 50, 50, 0.3)",
      border: "1.5px solid var(--original-grey)",

      ":hover": {
        cursor: "pointer",
        backgroundColor: "var(--p-pink)",
        color: " white",
      },
    },
    arrow: {
      width: "16px",
      transition: "all .5s ease-in-out ",
    },
  };

  const tabs = [
    [
      "Barter Agreement",
      `  This is a standard agreement that covers most cases and simply makes
          the terms of your exchange official.`,
    ],
    [
      "Online Business and Consumer Goods Services",
      ` This covers cases where professional goods or services are being
          exchanged, and can be used along side the Barter Agreement if you
          like. 
          Use this if you are exchanging your own professional products or
          services, or when working on long term projects together based on a
          value exchange.`,
    ],
    [
      "Confidentiality Agreement",
      `This is a mutual confidentiality agreement that can cover cases where
          both parties will be disclosing confidential information as part of
          their trade. Confidentiality agreements, also known as non-disclosure
          agreements (NDAs), aim to ensure the information disclosed under the
          agreement remains confidential and kept secret, only being used for
          the purposes defined in the trade.`,
    ],
  ];
  return (
    <StyleRoot>
      <div
        className={["flexCol", "fadeInAnimation"].join(" ")}
        style={style.widgetContainer}>
        <div style={{ alignSelf: "flex-end" }}>
          <IconWithToolTip
            src={CloseIcon}
            label="Close"
            click={props.showOrHideTradeAgreementWidget.bind(this, false)}
          />
        </div>
        <small style={{ margin: "1rem" }} className="modalTitle">
          You can use our official agreements to support your trade-off! <br />
          <br />
          These are available for you to use as soon as you click on ‘Trade
          Off’. You can still continue to use the chat.
          <br />
          To read more about each document and it's uses, click below. <br />
        </small>
        {tabs.map((i, index) => {
          return (
            <React.Fragment key={i[0] + 1}>
              <div
                key={i[0] + index}
                style={
                  showTabContent[i[0]]
                    ? [style.tab, style.tab[":hover"]]
                    : style.tab
                }
                className={["tab", "formInput"].join(" ")}
                onClick={(tab) => switchTabContent(i[0])}>
                {i[0]}
                <img
                  className="plus"
                  style={
                    showTabContent[i[0]]
                      ? [style.arrow, { filter: "invert(1)" }]
                      : style.arrow
                  }
                  src={showTabContent[i[0]] ? Minus : Arrow}
                  alt=""
                />
              </div>
              {showTabContent[i[0]] && <p className="modalText"> {i[1]} </p>}
            </React.Fragment>
          );
        })}
      </div>
    </StyleRoot>
  );
};

export default Radium(NDADownloadWidget);
