import { useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const useRequest = () => {
  const state = useSelector((state) => state);
  const reqSender = useCallback((url, method, data) => {
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        data: data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          Authorization: `Bearer ${state.isAuth}`,
        },
        url,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  });
  return {
    userID: state.userId,
    reqSender,
  };
};

export default useRequest;
