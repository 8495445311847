import React, { useRef } from "react";
import Radium, { StyleRoot } from "radium";

import useFileUpload from "../../../../hooks/useFileUpload";
import useRequest from "../../../../hooks/useRequest";
import MainBtn from "../../../UI/MainButton/MainButton";
import Spinner from "../../../UI/Spinner/Spinner";
import FILE from "../../../../assets/images/file_icon.png";
import PDF from "../../../../assets/images/pdf_logo.png";
import WORD from "../../../../assets/images/word_logo.png";

const FileUploader = (props) => {
  const uploadRef = useRef();
  const { reqSender, userID } = useRequest();
  const {
    fileUploader,
    cancelFileUpload,
    setFile,
    setFileLoading,
    setFileError,
    previewObject,
    fileError,
    fileLoading,
    file,
  } = useFileUpload();

  const shareFile = (file) => {
    setFileLoading(true);
    props.shareFile(file).then(setFileLoading(false));
    setFile();
  };
  const style = {
    previewImg: {
      width: "80px",
      height: "80px",
      objectFit: "cover",
      margin: "5px",
      borderRadius: ".5rem",
    },
    previewIcon: {
      width: "50px",
      height: "50px",
      margin: "5px",
    },
  };
  return (
    <StyleRoot>
      <div
        className="flexCol"
        style={{
          minWidth: "125px",
          maxWidth: "125px",
          height: "100%",
          margin: "20px",
        }}>
        {!file && (
          <img src={FILE} style={style.previewIcon} alt="upload file" />
        )}
        {fileError ? (
          <small className="modalTitle" style={{ color: "red" }}>
            An Error occured...
          </small>
        ) : fileLoading ? (
          <Spinner />
        ) : file && previewObject ? (
          <img style={style.previewImg} src={previewObject} />
        ) : file && file.type == "application/pdf" ? (
          <React.Fragment>
            <img style={style.previewIcon} src={PDF} />
            <small className="modalText">{file.name}</small>
          </React.Fragment>
        ) : (file &&
            file.type ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document") ||
          (file && file.type == "application/msword") ? (
          <React.Fragment>
            <img style={style.previewIcon} src={WORD} />
            <small className="modalText">{file.name}</small>
          </React.Fragment>
        ) : null}
        <div className="flexCol">
          <MainBtn
            type="button"
            click={
              file ? shareFile.bind(this, file) : () => fileUploader(uploadRef)
            }
            label={file ? "Send File" : "Upload File"}
          />
          {file && (
            <small onClick={cancelFileUpload} className="clickableSmall">
              Cancel
            </small>
          )}
        </div>
        <input
          ref={uploadRef}
          multiple
          type="file"
          name="file"
          id="file"
          style={{ display: "none" }}
          accept=".jpg, .png, .jpeg, .pdf, .doc, .docx"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>
    </StyleRoot>
  );
};

export default Radium(FileUploader);
