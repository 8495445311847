import React from "react";
import Balloons from "../../../assets/images/congrats.png";
import MainButton from "../MainButton/MainButton";
import Arrow from "../../../assets/images/arrow1.png";
import { connect } from "react-redux";
const CongratsScreen = (props) => {
  const style = {
    backgroundColor: "white",
    margin: "3rem 1rem",
    padding: "1.5rem 1.5rem",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (maxWidth: 770px)": {
      padding: "0.5rem",
    },
    arrow: {
      width: "16px",
      transition: "all .5s ease-in-out ",
    },
    link: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ":hover": {
        cursor: "pointer",
        backgroundColor: "var(--p-pink)",
        color: " white",
      },
    },
  };
  const labels = [
    "Download Barter Agreement",
    "Download Confidentiality Agreement",
    "Download Online Business and Consumer Goods Agreement",
  ];
  const links = [
    "https://peicst.com/BarterAgreementTemplate.pdf",
    "https://peicst.com/ConfidentialityAgreement.pdf",
    "https://peicst.com/OnlineBusinessAndConsumerGoodsServices.pdf",
  ];
  return (
    <div style={style}>
      <h1 className="headings">
        Congratulations<span className="spanRed">!</span>
      </h1>
      <img
        style={{ width: "50px", margin: "1rem" }}
        src={Balloons}
        alt="Congratulaions"
      />
      <p style={{ textAlign: "center" }} className="modalText">
        {props.children}
      </p>
      {props.download &&
        labels.map((i, index) => {
          return (
            <React.Fragment key={index * 67}>
              <a
                key={i}
                style={style.link}
                className={["downloads", "tab", "formInput"].join(" ")}
                href={links[index]}
                download
                target="_blank"
                rel="noopener noreferrer">
                {i}
                <img
                  key={i + index}
                  className="plus"
                  style={style.arrow}
                  src={Arrow}
                  alt=""
                />
              </a>
            </React.Fragment>
          );
        })}

      <MainButton click={props.back} label="Ok" />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuth: state.isAuth,
  };
};
export default connect(mapStateToProps)(CongratsScreen);
