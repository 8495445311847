import React from "react";
import Radium, { StyleRoot } from "radium";

import { withRouter } from "react-router-dom";
import { HashLink as NavLink } from "react-router-hash-link";

import Facebook from "../../assets/images/faceBook.png";
import Twitter from "../../assets/images/twitter.png";
import Insta from "../../assets/images/instaGram.png";
import Donate from "../../assets/images/footerDonate.png";

const Footer = (props) => {
  const style = {
    container: {
      position: "relative",
      bottom: "0",
      left: "0",
      zIndex: "99",
      margin: "auto",
      background: "var(--p-grey)",
      minWidth: "100vw",
      minHeight: "50vh",
      padding: "0 20px",
    },
    linkContainers: {
      padding: "1rem",
      flexFlow: "column",
      alignItems: "flex-start",
    },
  };
  const links = ["Documents & Features", "Terms & Conditions", "Disclaimer"];

  const navButts = [
    "Home",
    "How it Works",
    "About us",
    "FAQs",
    "Donate",
    "Contact",
  ];
  const socials = [Facebook, Twitter, Insta];
  return (
    <StyleRoot>
      <div className="flexCol" style={style.container}>
        <div className="flexRow">
          <div className="flexCol">
            {socials.map((i, index) => {
              return (
                <img
                  style={{ width: "35px", margin: "10px" }}
                  key={i + index}
                  src={i}
                  alt="Socials"
                />
              );
            })}
          </div>

          <div className="flexRow" style={style.linkContainers}>
            {navButts.map((btn, index) => {
              return (
                <NavLink
                  key={btn}
                  to={`Landing#${btn}`}
                  className="footerLinks">
                  {btn}
                </NavLink>
              );
            })}
          </div>
          <div className="flexRow" style={style.linkContainers}>
            {links.map((link, index) => {
              return (
                <NavLink
                  key={link}
                  to={
                    link === "Terms & Conditions"
                      ? "/MainTerms"
                      : `Landing#FAQs`
                  }
                  className="footerLinks">
                  {link}
                </NavLink>
              );
            })}
          </div>
          <p className="footerLinks">
            Offices A13-A14 <br></br>
            <br></br>Champions Business Park, <br></br>
            <br></br> Arrowe Brook Road,<br></br>
            <br></br> Wirral, <br></br>
            <br></br>Merseyside, <br></br>
            <br></br>United Kingdom,
            <br></br>
            <br></br>CH49 0AB
          </p>
        </div>
        <form
          style={{}}
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="2KND8U4SNZ9M4" />
          <input
            type="image"
            src={Donate}
            border="0"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
            style={{ width: "7rem", margin: "10px 0" }}
          />
        </form>

        <small className="footerLinks">
          All Rights Reserved © Copyright 2020
        </small>
      </div>
    </StyleRoot>
  );
};

export default withRouter(Radium(Footer));
