import React from "react";
import Radium, { StyleRoot } from "radium";
import DWNLD from "../../../../assets/images/dwnld_icon.png";

const ChatMsgBubble = (props) => {
  return (
    <StyleRoot>
      <div
        className={["bubble", "flexCol"].join(" ")}
        style={
          props.authorId === props.userId
            ? {
                backgroundColor: "#ed8cbb4d",
              }
            : {
                backgroundColor: "#78686f4d",
              }
        }>
        <div
          style={{
            alignSelf: "flex-start",
            gridArea: " 1 / 1 / span 1/ span 3",
          }}>
          <span style={{ fontFamily: "var(--main-font)" }}>
            {props.authorName}
          </span>
          <span style={{ margin: "0 3px" }}> | </span>
          <span
            style={{
              color: "var(--p-grey)",
              "@media (max-width: 770px)": {
                fontSize: ".8em",
              },
            }}>
            {props.timeStamp}
          </span>
        </div>
        <div
          style={{
            alignSelf: "flex-start",
            gridArea: "2 / 1 / span 1 / span 3 ",
            margin: "0.5rem",
          }}
          className="modalText">
          {props.file && props.authorId === props.userId ? (
            <small className="modalTitle">
              Your file has been shared successfully!
            </small>
          ) : props.file ? (
            <div className="flexCol">
              <small className="modalTitle">
                Click to download shared file!{" "}
              </small>
              <a
                href={`https://peicst.com/${props.file}`}
                download
                target="_blank"
                rel="noopener noreferrer">
                <img src={DWNLD} style={{ width: "40px" }} />{" "}
              </a>
            </div>
          ) : (
            props.body
          )}
        </div>
      </div>
    </StyleRoot>
  );
};

export default Radium(ChatMsgBubble);
