import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import * as Util from "../../store/utility";

import MainButton from "../UI/MainButton/MainButton";
import CongratsScreen from "../UI/congratsScreen/congratsScreen";
import Radium, { StyleRoot } from "radium";
import Loader from "../UI/Spinner/Spinner";
import useRequest from "../../hooks/useRequest";

const ChatWindow = (props) => {
  const [messages, setmessages] = useState(props.chat);
  const [newMessage, setnewMessage] = useState("");
  const [congrats, setcongrats] = useState(false);
  const [loader, setLoader] = useState(false);
  const socketRef = useRef(null);
  const bottomRef = useRef();
  const token = props.isAuth;
  const { userID, reqSender } = useRequest();

  const takeUserOffline = (cb) => {
    setLoader(true);
    reqSender(`/api/chats/remove/${props.id}/${userID}`, "PATCH")
      .then((res) => {
        console.log(res);
        setLoader(false);
        if (cb) {
          cb();
        }
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  var referrer = document.referrer;
  console.log("referrer url", referrer);

  useEffect(() => {
    Util.scroll(bottomRef);
    window.onbeforeunload = () => {
      return takeUserOffline();
    };
    return () => {
      takeUserOffline();
      window.onbeforeunload = () => {
        return takeUserOffline();
      };
    };
  }, []);
  const cancelChat = () => {
    takeUserOffline(props.cancelChat);
  };

  useEffect(() => {
    socketRef.current = io("", {
      query: { token },
    });
    socketRef.current.on("connection", console.log("connected"));
    socketRef.current.emit("USER_CONNECTED", props.userId);
    socketRef.current.on("MESSAGE", (message) => {
      inbox(message);
      Util.scroll(bottomRef);
    });
    return () => {
      socketRef.current.emit("USER_DISCONNECTED", props.userId);
    };
  }, [props.userId, token]);

  const inbox = (message) => {
    setmessages((messages) => [...messages, message]);
  };
  console.log(messages);
  const sendMessage = (e) => {
    e.preventDefault();
    const recipient =
      messages[messages.length - 1].authorId === props.userId
        ? messages[messages.length - 1].recipient
        : messages[messages.length - 1].authorId;
    const msg = {
      recipient: recipient,
      authorId: props.userId,
      timeStamp: Util.formatTimestamp(new Date()),
      authorName: props.userName,
      body: newMessage,
      authorImage: props.image,
    };
    reqSender(`/api/chats/${props.id}`, "POST", msg)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setnewMessage("");
    socketRef.current.emit("SEND_MESSAGE", recipient, msg);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };
  const messageHandler = (e) => {
    setnewMessage(e.target.value);
    keyPress(e);
  };
  const congratsTrue = () => {
    reqSender(
      `/api/posts/status/${props.offerRef}`,
      "PATCH",
      JSON.stringify({
        data: "Complete",
      })
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setcongrats(true);
  };

  const congratsDone = () => {
    setcongrats(false);
  };

  const style = {
    window: {
      overflowY: "scroll",
      backgroundColor: "white",
      width: "100%",
      height: "50vh",
      borderRadius: "1rem",
      margin: "0 auto",
      padding: "1rem",
      display: "flex",
      flexFlow: "column",
      "@media (max-width: 770px)": {
        maxWidth: "100%",
        overflowX: "hidden",
        height: "40vh",
      },
    },
  };
  if (congrats) {
    return (
      <CongratsScreen download back={congratsDone}>
        You have made a trade-off! <br></br>You can now access our official
        trade agreements to complete your trade-off!<br></br>
        Download links are below.
      </CongratsScreen>
    );
  } else if (loader) {
    return <Loader />;
  } else {
    return (
      <StyleRoot>
        <div style={{ height: "100%" }}>
          <div style={style.window}>
            {messages.map((i, index) => {
              return (
                <React.Fragment key={i + index}>
                  <div
                    key={i.author + index}
                    authorid={i.authorId}
                    className="bubble"
                    style={
                      i.authorId === props.userId
                        ? {
                            alignSelf: "flex-end",
                            backgroundColor: "#ed8cbb4d",
                          }
                        : {
                            alignSelf: "flex-start",
                            backgroundColor: "#78686f4d",
                          }
                    }>
                    <div
                      key="divider"
                      style={{ gridArea: " 1 / 1 / span 1/ span 3" }}>
                      <span
                        key="authorSpan"
                        style={{ fontFamily: "var(--main-font)" }}>
                        {i.authorName}
                      </span>
                      <span key="anotherDivider" style={{ margin: "0 3px" }}>
                        {" "}
                        |{" "}
                      </span>
                      <span
                        key="dateSpan"
                        style={{
                          color: "var(--p-grey)",
                          "@media (max-width: 770px)": {
                            fontSize: ".8em",
                          },
                        }}>
                        {i.timeStamp}
                      </span>
                    </div>
                    <div
                      key="messageHolder"
                      style={{
                        gridArea: "2 / 1 / span 1 / span 3 ",
                        margin: "0.5rem",
                      }}
                      className="modalText">
                      {i.body}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div ref={bottomRef} />
          </div>
          <form
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
            }}>
            <textarea
              name="bio"
              key="bioInput"
              id="placeholder"
              className={["formInput", "formTextArea"].join(" ")}
              onKeyPress={keyPress}
              rows="5"
              value={newMessage}
              onChange={messageHandler}
            />

            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width: 770px)": {
                  flexDirection: "row-reverse",
                },
              }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MainButton
                  type="button"
                  label="Trade Off"
                  click={congratsTrue}
                />
                <label className="clickableSmall" onClick={cancelChat}>
                  {" "}
                  Exit Chat
                </label>
              </div>

              <div>
                <label className="clickableSmall" onClick={sendMessage}>
                  {" "}
                  Send{" "}
                </label>
              </div>
            </div>
          </form>
        </div>
      </StyleRoot>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.isAuth,
    userId: state.userId,
    userName: state.userName,
    image: state.image,
  };
};

export default connect(mapStateToProps)(Radium(ChatWindow));
