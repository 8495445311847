import React from "react";
import Radium, { StyleRoot } from "radium";

import ChatIcon from "../../../assets/images/chat.png";
import EnvelopeIcon from "../../../assets/images/envelope.png";

const NotificationItem = (props) => {
  const style = {
    itemContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "1px solid rgba(50, 50, 50, 0.05)",
      borderRadius: "1.5rem",
      boxShadow: "0px 15px 15px 3px rgba(50, 50, 50, 0.3)",
      width: "650px",
      height: "6rem",
      margin: "15px",
      padding: "20px",
      backgroundColor: "white",
      "@media (max-width: 770px)": {
        width: "450px",
      },
    },
    textOne: {
      fontFamily: "var(--main-font)",
      fontSize: ".8em",
      margin: "5px",
      padding: "0 0 0 1rem",
    },
  };

  const showIt = () => {
    props.viewer(props.id, props.type);
  };

  return (
    <StyleRoot>
      <div className="itemContainer" style={{ backgroundColor: "white" }}>
        <img
          alt="avatar"
          src={props.type === "Message" ? EnvelopeIcon : ChatIcon}
          style={{ width: "30px", padding: "5px" }}
        />
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "5px",
            width: "500px",
            "@media (max-width: 770px)": {
              width: "400px",
            },
          }}>
          <small
            style={{
              fontFamily: "var(--main-font)",
              margin: "5px",
              padding: "0 0 0 1rem",
              color: "var(--p-grey)",
            }}>
            {props.type === "Message" ? "New Message:" : "New Negotiation:"}
          </small>
          <h5 style={style.textOne}>
            {props.userName} <span style={{ margin: "2px" }}>|</span>{" "}
            {props.timeStamp}
          </h5>

          <p className="modalText" style={{ marginBottom: "8px" }}>
            "
            {props.body.length > 100
              ? props.body.slice(0, 100) + "..."
              : props.body}
            "
          </p>
        </div>
        <small
          onClick={showIt}
          className="clickableSmall"
          style={{ alignSelf: "flex-end" }}>
          View
        </small>
      </div>
    </StyleRoot>
  );
};

export default Radium(NotificationItem);
