import React from "react";

const error = (props) => {
  return (
    <h1
      style={{
        fontFamily: "var(--main-font)",
        fontSize: "2em",
        color: "var(--original-grey)",
        margin: "25% auto",
      }}
    >
      Oops, something went wrong! Please refresh the page...
    </h1>
  );
};
export default error;
