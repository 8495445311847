import { useState, useEffect } from "react";
import * as Yup from "yup";

const useFileUpload = () => {
  const [previewObject, setPreviewObject] = useState();
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [fileLoading, setFileLoading] = useState(false);

  const previewTypes = ["image/jpg", "image/jpeg", "image/png"];
  const fileTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];
  const schema = Yup.object({
    newFile: Yup.mixed()
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && fileTypes.includes(value.type)
      )
      .test(
        "fileSize",
        "File is too large, please try another.",
        (value) => value.size <= 28000000
      ),
  });

  const fileUploader = (ref) => {
    if (!file) {
      ref.current.click();
    }
  };

  useEffect(() => {
    console.log("Running File Uploader...");
    if (!file) {
      return;
    }
    setFileLoading(true);
    schema
      .validate({ newFile: file })
      .then(() => {
        setFileError(false);
        if (previewTypes.includes(file.type)) {
          setPreviewObject(URL.createObjectURL(file));
        }
        setFileLoading(false);
      })
      .catch((err) => {
        setFileLoading(false);
        setFileError(err.message);
      });
    return () => {
      URL.revokeObjectURL(file);
    };
  }, [file]);

  const cancelFileUpload = () => {
    setFileError(false);
    setPreviewObject(null);
    setFile(null);
  };

  return {
    fileUploader,
    cancelFileUpload,
    setFile,
    setPreviewObject,
    setFileLoading,
    setFileError,
    previewObject,
    fileError,
    fileLoading,
    file,
  };
};

export default useFileUpload;
