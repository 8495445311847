import React from "react";
import Radium, { StyleRoot } from "radium";
import Btn from "../UI/MainButton/MainButton";
import { withRouter } from "react-router-dom";

const ImplicitConsentWidget = (props) => {
  const navHandler = () => {
    props.history.push("/MainTerms");
  };

  const style = {
    container: {
      position: "fixed",
      top: "calc(100vh - 3.2rem)",
      left: "0",
      zIndex: "100",
      margin: "auto",
      background: "var(--p-grey)",
      minWidth: "100vw",
      minHeight: "8vh",
      maxHeight: "8vh",
      justifyContent: "space-evenly",
      "@media (max-width: 770px)": {
        fontSize: ".8em",
      },
    },
    link: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
  return (
    <StyleRoot>
      <div className="flexRow" style={style.container}>
        <small className="modalTitle" style={{ color: "white" }}>
          {" "}
          By continuing to use PEICST you implicitly agree to our{" "}
          <span onClick={navHandler} style={style.link}>
            Terms of use{" "}
          </span>{" "}
          and{" "}
          <span onClick={navHandler} style={style.link}>
            Privacy Policy
          </span>
          .{" "}
        </small>
        <Btn label="OK" click={props.cancelWidget} />
      </div>
      ;
    </StyleRoot>
  );
};

export default withRouter(Radium(ImplicitConsentWidget));
