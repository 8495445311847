import React from "react";
import Radium, { StyleRoot } from "radium";

const IconWithToolTip = (props) => {
  return (
    <StyleRoot>
      <span onClick={props.click} data-text={props.label} className="toolTip">
        <img className="icons" src={props.src} alt={props.label} />
      </span>
    </StyleRoot>
  );
};

export default Radium(IconWithToolTip);
