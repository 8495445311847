import React from "react";
import Radium, { StyleRoot } from "radium";
import { withRouter } from "react-router-dom";

const Logo = (props) => {
  const goHome = () => {
    props.history.push("/Home");
  };

  const style = {
    Logo: {
      margin: ".5rem",
      color: "red",
      fontSize: "1.3rem",
      fontFamily: "var(--main-font)",
      fontWeight: "bolder",
    },
    Cursive: {
      margin: ".5rem",
      fontFamily: "var(--cursive)",
      color: "var(--p-sub-grey)",
      fontSize: "1.3em",
      fontWeight: "lighter",
    },
  };
  return (
    <StyleRoot>
      <div
        onClick={goHome}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <h1 style={style.Logo}>{props.logo}</h1>
        <h3 style={style.Cursive}>
          People Exploring ideas Create something tangible
        </h3>
      </div>
    </StyleRoot>
  );
};
export default withRouter(Radium(Logo));
