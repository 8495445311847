export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SET_USERNAME = "AUTH_SET_USERNAME ";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SESH_TIMEOUT = "AUTH_SESH_TIMEOUT";
export const AUTH_IGNORE_SESH_TIMEOUT = "AUTH_IGNORE_SESH_TIMEOUT";
export const SET_INBOX = "SET_INBOX";
export const SET_BIO = "SET_BIO";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";
