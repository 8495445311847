import React from "react";
import * as actions from "../../../store/actions/actions";
import { connect } from "react-redux";
import FormCreator from "../formCreator";
import * as Yup from "yup";
import Radium, { StyleRoot } from "radium";

const LoginForm = (props) => {
	const validationSchema = Yup.object({
		email: Yup.string()
			.required("Required")
			.email("Please enter a valid email"),
		password: Yup.string()
			.required("Required")
			.min(6, "Password must be at least 6 characters")
			.max(15, "Password must be 15 or less characters"),
	});
	const cancel = () => {
		return;
	};
	const onSubmit = (values) => {
		props.onAuthSignIn(values, "login");
	};
	return (
		<StyleRoot>
			<React.Fragment>
				<div
					style={{
						marginLeft: props.seshTimeOut ? null : "1rem",
						width: props.seshTimeOut ? "100%" : "400px",
						"@media (max-width: 770px)": {
							margin: "auto",
						},
					}}>
					<div
						style={{
							marginLeft: props.seshTimeOut ? null : "3rem",
						}}>
						{" "}
						{props.seshTimeOut ? (
							<h3 className="headings" style={{ fontSize: "1.5em" }}>
								You've been logged out. <br></br>
								<span className="spanRed">sign in </span>
								to continue where you left off...
							</h3>
						) : (
							<h3 className="headings" style={{ fontSize: "1.5em" }}>
								Already a member? <br></br>
								<span className="spanRed">Welcome </span>
								back!
							</h3>
						)}
					</div>
					<FormCreator
						nameTypes={["email", "password"]}
						labels={["Email", "Password"]}
						inputTypes={["email", "password"]}
						submitLogic={(values) => onSubmit(values)}
						validationSchema={validationSchema}
						initialValues={{
							email: "",
							password: "",
						}}
						submitBtnLabel="Login"
						ratingNeeded={false}
						mainFont
						cancel={props.seshTimeOut ? props.closeModal : cancel}
						buttonStyleLeft
					/>
					{!props.seshTimeOut && (
						<small
							style={{ marginLeft: "1.5rem" }}
							className="clickableSmall"
							onClick={(bool) => props.togglePassReset(true)}>
							Forgot Password?{" "}
						</small>
					)}

					{props.errorLogIn ? (
						<small className="red">Error: {props.errorLogIn}</small>
					) : null}
				</div>
			</React.Fragment>
		</StyleRoot>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAuthSignIn: (values, source) =>
			dispatch(actions.authenticate(values, source)),
	};
};

export default connect(null, mapDispatchToProps)(Radium(LoginForm));
