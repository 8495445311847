import React from "react";
import Modal from "../UI/Modal/Modal";
import Btn from "../UI/MainButton/MainButton";

export const Disclaimer = (props) => {
  return (
    <Modal
      width="500px"
      height="500px"
      left={"calc(50% - 250px)"}
      top={"calc(50% - 250px)"}
      click={props.cancelDisclaimer}
      show={props.showDisclaimer}>
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "auto",
          padding: "1rem",
          "@media (max-width: 770px)": {
            padding: ".5rem",
          },
        }}>
        <h1
          className="headings"
          style={{
            margin: "1rem",
          }}>
          {" "}
          Hey
          <span className="spanRed"> there</span>!
        </h1>
        <p
          className="text"
          style={{
            margin: "1rem",
            "@media (max-width: 770px)": {
              margin: "2rem 1rem",
            },
          }}>
          Welcome to PEICST! We are slowly launching! <br />
          <br />
          We are developing features to make PEICST an enjoyable resource for
          you so please bear with us.
          <br /> <br />
          In the meantime have a look around and try us out, navigate, register
          and begin using Peicst it! <br />
          If you'd like to report anything about your user experience , please
          get in touch by completing our contact form. <br />
          <br />
          Many Thanks, <br />
          Team PEICST
        </p>
        <Btn label="Got It" click={props.cancelDisclaimer} />
      </div>
    </Modal>
  );
};

export default Disclaimer;
