import { useFormik } from "formik";
import React from "react";
import MainButton from "../UI/MainButton/MainButton";
import Rating from "react-rating";
import Heart from "../../assets/images/heart.png";
import HeartPink from "../../assets/images/heartPink.png";
import { Link } from "react-router-dom";
import Radium, { StyleRoot } from "radium";

const FormCreator = (props) => {
  const formik = useFormik({
    initialValues: props.initialValues,
    onSubmit: (values) => {
      if (formik.values.rating) {
        formik.values.rating = props.rating;
      }
      props.submitLogic(values);
    },
    validationSchema: props.validationSchema,
  });
  const cancel = () => {
    props.cancel();
    formik.resetForm();
  };

  const TYPES = ["text", "email", "password", "password"];
  return (
    <StyleRoot>
      <form className="formStyle" onSubmit={formik.handleSubmit}>
        {props.selects
          ? props.selectNameTypes.map((i, index) => {
              return (
                <div
                  key={index * 74}
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    minWidth: "50%",
                  }}>
                  <label
                    key={index * 60}
                    htmlFor={i}
                    className={props.mainFont ? "modalTitle" : "modalText"}>
                    {" "}
                    {props.selectLabels[index]}
                  </label>
                  <select
                    style={
                      formik.touched[i] && formik.errors[i]
                        ? {
                            border: "2px solid var(--p-red)",
                            display: "block",
                            width: "100%",
                          }
                        : { display: "block", width: "100%" }
                    }
                    name={i}
                    key={i + index}
                    className={["formInput", "formSelect"].join(" ")}
                    {...formik.getFieldProps(i)}>
                    {props.optionNameTypes[index].map((i) => {
                      return <option key={`optionKey${i}`}>{i}</option>;
                    })}
                  </select>
                  {formik.touched[i] && formik.errors[i] ? (
                    <small style={{ marginRight: "50%" }} className="red">
                      {formik.errors[i]}
                    </small>
                  ) : null}
                </div>
              );
            })
          : null}

        {props.nameTypes
          ? props.nameTypes.map((i, index) => {
              return (
                <React.Fragment key={index * 45}>
                  <label
                    key={i + index}
                    htmlFor={i}
                    className={props.mainFont ? "modalTitle" : "modalText"}>
                    {" "}
                    {props.labels[index]}
                  </label>
                  {props.small && (
                    <small style={{ margin: ".2rem" }} className="modalText">
                      {props.small[index]}
                    </small>
                  )}
                  <div
                    key={index + 1}
                    style={{
                      margin: "0",
                      width: "100%",
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "flex-start",
                    }}>
                    <input
                      key={i}
                      type={props.inputTypes[index]}
                      name={i}
                      className={
                        TYPES.includes(props.inputTypes[index]) &&
                        formik.touched[i] &&
                        formik.errors[i]
                          ? "redBorderFormInput"
                          : TYPES.includes(props.inputTypes[index])
                          ? ["formInput", "dark"].join(" ")
                          : null
                      }
                      autoComplete="off"
                      {...formik.getFieldProps(i)}
                      placeholder={
                        props.placeholders && props.placeholders[index]
                      }
                    />
                  </div>
                  {formik.touched[i] && formik.errors[i] ? (
                    <small style={{ marginRight: "50%" }} className="red">
                      {formik.errors[i]}
                    </small>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
        {props.textAreas
          ? props.textAreasNameTypes.map((i, index) => {
              return (
                <React.Fragment key={i + 1}>
                  <label
                    key={i + "textArea"}
                    htmlFor={i}
                    className="modalTitle">
                    {props.textAreaLabels[index]}
                  </label>
                  <textarea
                    name={i}
                    key={i + index * 45}
                    rows="5"
                    className={
                      formik.touched[i] && formik.errors[i]
                        ? "redBorderFormInput"
                        : ["formInput", "formTextArea", "dark"].join(" ")
                    }
                    placeholder={
                      props.textAreaPlaceHolders &&
                      props.textAreaPlaceHolders[index]
                    }
                    {...formik.getFieldProps(i)}
                  />
                  {formik.touched[i] && formik.errors[i] ? (
                    <small className="red">{formik.errors[i]}</small>
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
        {props.checkbox
          ? props.checkboxNameType.map((i, index) => {
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1rem",
                  }}>
                  <input
                    key={i + index + 5}
                    name={i}
                    type="checkbox"
                    {...formik.getFieldProps(i)}
                  />
                  <label
                    key={i + index + 7}
                    htmlFor={i}
                    className={props.mainFont ? "modalTitle" : "modalText"}>
                    {props.checkboxLabel[index]}
                  </label>
                  {formik.touched[i] && formik.errors[i] ? (
                    <small style={{ marginRight: "50%" }} className="red">
                      {formik.errors[i]}
                    </small>
                  ) : null}
                </div>
              );
            })
          : null}

        {props.ratingNeeded ? (
          <React.Fragment>
            <label className="modalText">{props.feedbackLabel}</label>
            <div
              style={{
                margin: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}>
              <Rating
                stop="5"
                name="rating"
                id="rating"
                initialRating={props.rating}
                onChange={(value) => props.showFeedBack(value)}
                onClick={(value) => props.updateRating(value)}
                emptySymbol={
                  <img alt="" style={{ margin: "1rem" }} src={Heart} />
                }
                fullSymbol={
                  <img
                    alt=""
                    style={{
                      margin: "1rem",
                      filter:
                        "invert(84%) sepia(30%) saturate(2078%) hue-rotate(286deg) brightness(98%) contrast(89%)",
                    }}
                    src={HeartPink}
                  />
                }
              />
              {props.feedback ? (
                <React.Fragment>
                  <textarea
                    id="ratingVerbal"
                    name="ratingVerbal"
                    style={{ maxWidth: "40%" }}
                    className={["formInput", "formTextArea", "dark"].join(" ")}
                    key={"in1"}
                    rows="3"
                    placeholder="Please tell us how to improve this rating..."
                    value={formik.values.ratingVerbal}
                    onChange={formik.handleChange}
                  />
                </React.Fragment>
              ) : null}
            </div>
          </React.Fragment>
        ) : null}
        <div
          style={
            props.buttonStyleLeft
              ? {
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }
              : {
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
          }>
          {props.loginMsg ? (
            <Link to="/LoginPage">
              <small className={["modalText", "clickableSmall"].join(" ")}>
                Must be logged in to create a trade!
              </small>
            </Link>
          ) : (
            <MainButton
              type="submit"
              click={formik.handleSubmit}
              label={props.submitBtnLabel}
            />
          )}

          <label
            onClick={cancel}
            style={{ textAlign: "center" }}
            className={["modalText", "clickableSmall"].join(" ")}>
            Cancel
          </label>
        </div>
      </form>
    </StyleRoot>
  );
};

export default Radium(FormCreator);
