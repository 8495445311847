import React from "react";
import Radium, { StyleRoot } from "radium";
import Fav from "../../../assets/images/heart.png";

const saveIcon = (props) => {
  return (
    <StyleRoot>
      <span onClick={props.click} data-text="Save" className="toolTip">
        <img className="icons" src={Fav} alt="Save" />
      </span>
    </StyleRoot>
  );
};

export default Radium(saveIcon);
