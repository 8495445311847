import * as actionTypes from "./actions/actionTypes";

const initialState = {
	userId: null,
	userName: null,
	isAuth: null,
	errorSignUp: null,
	errorLogIn: null,
	loading: false,
	logout: false,
	image: null,
	feedback: null,
	inbox: 0,
	bio: null,
	sessionTimeOut: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return { ...state, errorSignUp: null, errorLogIn: null, loading: true };
		case actionTypes.AUTH_SUCCESS:
			return {
				...state,
				userId: action.id,
				userName: action.userName,
				isAuth: action.token,
				errorSignUp: null,
				errorLogIn: null,
				loading: false,
				image: action.image,
				feedback: action.feedback,
				inbox: action.notifyInbox,
				bio: action.bio,
				sessionTimeOut: false,
			};
		case actionTypes.AUTH_SET_USERNAME:
			return { ...state, userName: action.userName };
		case actionTypes.AUTH_FAIL:
			if (action.errorLogIn) {
				return { ...state, errorLogIn: action.errorLogIn, loading: false };
			} else {
				return { ...state, errorSignUp: action.errorSignUp, loading: false };
			}
		case actionTypes.AUTH_LOGOUT:
			return {
				...state,
				userId: null,
				userName: null,
				isAuth: null,
				image: null,
				feedback: null,
			};
		case actionTypes.AUTH_SESH_TIMEOUT:
			return {
				...state,
				userId: null,
				userName: null,
				isAuth: null,
				image: null,
				feedback: null,
				sessionTimeOut: true,
			};
		case actionTypes.AUTH_IGNORE_SESH_TIMEOUT:
			return {
				...state,
				sessionTimeOut: false,
			};

		case actionTypes.SET_INBOX:
			return {
				...state,
				inbox: action.notifyInbox,
			};
		case actionTypes.SET_BIO:
			return {
				...state,
				bio: action.bio,
			};
		default:
			return state;
	}
};
export default reducer;
