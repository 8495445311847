import React, { useState } from "react";
import Radium, { StyleRoot } from "radium";
import { connect } from "react-redux";
import Accepted from "../../UI/icons/acceptedIcon";
import Reply from "../../UI/icons/replyIcon";
import Delete from "../../UI/icons/deleteIcon";
import * as Util from "../../../store/utility";
import Avi from "../../../assets/images/avatar.png";

const NegotiationItem = (props) => {
  const [showFullText, setshowFullText] = useState(false);
  const textToggle = () => {
    setshowFullText(!showFullText);
  };

  const toggleViewReplies = () => {
    if (props.isViewingReplies !== props.id) {
      props.onShowReplies(props.id);
    } else {
      props.onHideReplies(null);
    }
  };

  const data = {
    recipientName: props.author,
    id: props.id,
    postId: props.postId,
    offerTitle: props.offerTitle,
    authorId: props.userId,
    authorName: props.userName,
    timeStamp: Util.formatTimestamp(new Date()),
    body: `Congratulations! ${props.userName} has accepted your offer. You can now iron out the details in a private chat. Good Luck!`,
    recipient: props.authorId,
    authorImage: props.image,
  };

  const style = {
    style: {
      backgroundColor: props.colorSwitch
        ? "white"
        : !props.readByPoster && props.userId === props.postAuthorId
        ? "#ed8cbb4d"
        : "white",
      width: "90%",
      maxHeight: "fit-content",
      borderTop: "1px solid #ccc",
      borderRadius: props.colorSwitch
        ? null
        : !props.readByPoster && props.userId === props.postAuthorId
        ? "2rem"
        : null,
      margin: "0 auto",
      padding: "15px",
      justifyContent: "flex-start",
      transition: "all 1.5s ease",
      "@media (max-width: 770px)": {
        width: "100%",
      },
    },
    avatar: {
      maxHeight: "50px",
      maxWidth: "50px",
      minHeight: "50px",
      minWidth: "50px",
      borderRadius: "2rem",
      margin: ".5rem 0",
      objectFit: "cover",
    },
    textOne: {
      fontFamily: "var(--main-font)",
      fontSize: ".8em",
      padding: "0 0 0 1rem",
      textAlign: "left",
    },
  };

  return (
    <React.Fragment>
      <StyleRoot>
        <div className="flexRow" style={style.style}>
          <div id="imageDiv" className="flexRow">
            <img
              style={style.avatar}
              src={props.avatar ? `http://peicst.com/${props.avatar}` : Avi}
              alt=""
            />
          </div>
          <div
            id="bodyDiv"
            className="flexCol"
            style={{ width: "90%", alignItems: "flex-start" }}>
            <div className="flexRow">
              <h5 style={style.textOne}>
                {props.author} <span style={{ margin: "0 2px" }}>|</span>{" "}
                {props.date}
              </h5>
            </div>

            <small className="modalText" style={{ margin: "5px 0" }}>
              {!showFullText && props.children.length > 180
                ? props.children.slice(0, 180) + "..."
                : props.children}
            </small>
            <span
              onClick={textToggle}
              style={(style.textOne, { color: "var(--p-grey)" })}
              className={["modalText", "clickableSmall"].join(" ")}>
              {" "}
              {showFullText
                ? "Read Less"
                : props.children.length > 180
                ? "Read More"
                : null}
            </span>
            <div
              id="ActionsDiv"
              className="flexRow"
              style={{
                width: "100%",
                justifyContent: "flex-start",
              }}>
              {props.nodes && props.nodes.length ? (
                <small
                  className={["modalText", "clickableSmall"].join(" ")}
                  style={(style.textOne, { color: "var(--p-grey)" })}
                  onClick={toggleViewReplies}>
                  {props.isViewingReplies === props.id
                    ? "Hide Replies"
                    : `View Replies (${props.nodes.length})`}
                </small>
              ) : null}
              {props.doNotShow ||
              props.authorId ===
                props.postAuthorId ? null : props.postAuthorId ===
                  props.userId && !props.accepted ? (
                <React.Fragment>
                  <small
                    className={["modalText", "clickableSmall"].join(" ")}
                    style={(style.textOne, { color: "var(--p-grey)" })}
                    onClick={() => props.onOfferAccepted(data)}
                    key="acc">
                    Accept Offer
                  </small>
                  <Reply click={() => props.isReplying(props.id)} />
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <div
            id="acceptedAndDeleteDiv"
            className="flexCol"
            style={{ justifySelf: "flex-end" }}>
            {props.accepted && <Accepted />}
            {props.authorId === props.userId && (
              <Delete click={props.onIsDeleted} />
            )}
          </div>
        </div>
      </StyleRoot>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    userName: state.userName,
    userId: state.userId,
    isAuth: state.isAuth,
    image: state.image,
  };
};
export default connect(mapStateToProps)(Radium(NegotiationItem));
