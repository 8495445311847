import React from "react";
import MainButton from "../../UI/MainButton/MainButton";
import Radium from "radium";
import { Link } from "react-router-dom";

const About = (props) => {
  return (
    <section
      id="About us"
      className="section"
      style={{
        height: "90vh",
        "@media (max-width: 770px)": {
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "fit-content",
        },
      }}>
      <div
        className="textContainer"
        style={{
          marginTop: "5rem",
          "@media (max-width: 770px)": {
            margin: "0",
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            minWidth: "90%",
          },
        }}>
        <h2 className="headings">
          {" "}
          <span className="spanRed"> About </span>Us
        </h2>
        <p
          className="text"
          style={{
            marginLeft: "2rem",
            "@media (max-width: 770px)": {
              margin: "auto",
            },
          }}>
          <span className="tagLine">
            Peicst was created to help communities grow, develop and contribute.
          </span>
          <br></br> <br></br>
          We believe people can achieve their full potential without depending
          on money, by trading tangible things of equal value or significance, a
          trade-off is an extraordinary concept. We created Peicst to help these
          exchanges come true. We want to support Peicsters and Peicstees with a
          connective platform and provide official trade agreements should you
          need them, all in one place and free.
        </p>
      </div>
      <div>
        <br></br> <br></br>
        <Link to="/LoginPage">
          <MainButton label="Create Account" />
        </Link>
      </div>
    </section>
  );
};
export default Radium(About);
