import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useRequest from "../../../hooks/useRequest";
import Send from "../../UI/icons/sendIcon";

const DetailsEditor = (props) => {
  const { reqSender, userID } = useRequest();
  const validationSchema = Yup.object({
    [props.name]: Yup.string()
      .trim("Oops, can't send an empty form!")
      .required("Oops, can't send an empty form!"),
  });

  const formik = useFormik({
    initialValues: { [props.name]: [props.default] },
    onSubmit: (values) => {
      props.loadingToggle();
      reqSender(
        `/api/posts/patch/${props.name}/${props.postId}`,
        "PATCH",
        JSON.stringify({ values })
      )
        .then((res) => {
          props.showNewContent(props.name, res.data);
        })
        .catch((err) => console.log(err));
      formik.resetForm();
    },
    validationSchema,
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flexRow"
      style={{ width: "90%" }}>
      <textarea
        className={["formInput", "formTextArea"].join(" ")}
        name={props.name}
        id={props.name}
        rows={props.rows}
        {...formik.getFieldProps(props.name)}
      />
      <Send click={formik.handleSubmit} />
      <small
        onClick={() => props.cancel(false)}
        className={["modalText", "clickableSmall"].join(" ")}>
        Cancel
      </small>
    </form>
  );
};

export default DetailsEditor;
