import React from "react";
import MainButton from "../../UI/MainButton/MainButton";
import { Link } from "react-router-dom";
import Click from "../../../assets/images/icons8-natural-user-interface-2-50 (2).png";
import Agree from "../../../assets/images/icons8-handshake-heart-50.png";
import Grow from "../../../assets/images/icons8-tree-planting-50 (1).png";
import Radium, { StyleRoot } from "radium";

const Works = (props) => {
  const style = {
    box: {
      marginTop: "5rem",
      padding: "1rem",
      width: "70%",
      height: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 770px)": {
        margin: "auto",
        height: "fit-content",
        width: "fit-content",
        flexFlow: "column",
      },
    },
    circle: {
      margin: "0 auto",
      padding: "3rem",
      clipPath: "circle(100px at 50% 50%)",
      borderRadius: "100%",
      backgroundColor: "var(--p-grey)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexFlow: "column",
      minWidth: "250px",
      minHeight: "250px",
      maxWidth: "250px",
      maxHeight: "250px",
      "@media (max-width: 770px)": {
        padding: "2rem",
        margin: "auto",
        minWidth: "200px",
        minHeight: "200px",
        maxWidth: "200px",
        maxHeight: "200px",
      },
    },
    numbers: {
      margin: "0",
      padding: "0",
      fontSize: "3em",
      fontWeight: "bold",
      fontFamily: "var(--main-font)",
      color: "var(--p-red)",
    },
    circleText: {
      margin: "0",
      padding: "0",
      textAlign: "center",
      color: "white",
      fontSize: " 0.85em",
      fontWeight: "bolder",
      lineHeight: "1.5",

      fontFamily: "var(--sub-font)",
      "@media (max-width: 770px)": {
        fontSize: ".75em",
      },
    },
    icons: {
      margin: ".25rem",
      padding: "0",
      maxWidth: "35px",
      maxHeight: "35px",
    },
  };

  return (
    <StyleRoot>
      <section
        style={{
          height: "90vh",
          "@media (max-width: 770px)": {
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-evenly",
            height: "fit-content",
          },
        }}
        className="section"
        id="How it Works">
        <div
          style={{
            position: "relative",
            top: "2rem",
            "@media (max-width: 770px)": {
              margin: "auto",
              position: "static",
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-evenly",
              minWidth: "90%",
            },
          }}
          className="textContainer">
          <h2 className="headings">
            How it <span className="spanRed">Works</span>
          </h2>
          <p
            className="text"
            style={{
              marginLeft: "2rem",
              "@media (max-width: 770px)": {
                margin: "auto",
                minWidth: "90%",
              },
            }}>
            <span className="tagLine">
              People doing extraordinary things, create incredible
              opportunities.
            </span>
            <br></br>
            <br></br>
            Peicst helps you trade by providing step by step processes. You can
            bid on as many trades as you like with an offer of equal value or
            significance, or create your own. You can Peicst your offer, say how
            valuable it is and what you want in return! Easily manage all your
            trades through your account. Click on 'Browse Trades' to explore or
            get started! <br></br>
          </p>
        </div>
        <div style={style.box}>
          <div style={style.circle}>
            <div style={style.numbers}>1</div>
            <p style={style.circleText}>
              Click on 'Browse Trades' to go to Peicst It! or create an account
              and post your own offer.
            </p>
            <img style={style.icons} src={Click} alt="" />
          </div>
          <div style={style.circle}>
            <div style={style.numbers}>2</div>
            <p style={style.circleText}>
              Negotiate the terms of your trade-off. You'll get live updates at
              each stage.
            </p>
            <img style={style.icons} src={Agree} alt="" />
          </div>
          <div style={style.circle}>
            <div style={style.numbers}>3</div>
            <p style={style.circleText}>
              Complete your trade-off with an official trade agreement and enjoy
              an opportunity that can't be bought!
            </p>
            <img style={style.icons} src={Grow} alt="" />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: ".5rem",
            "@media (max-width: 770px)": {
              position: "static",
            },
          }}>
          <Link to="/MarketPlace">
            <MainButton label="Browse Trades" />
          </Link>
        </div>
      </section>
    </StyleRoot>
  );
};

export default Radium(Works);
