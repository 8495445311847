export const updateObj = (oldObj, [formType], updatedVals) => {
  console.log(oldObj, formType, updatedVals);
  return {
    ...oldObj,
    [formType]: {
      ...oldObj[formType],
      ...updatedVals,
    },
  };
};
export const scroll = (ref) => {
  ref.current.scrollIntoView({ behavior: "smooth" });
};
export const keyPress = (e) => {
  if (e.key === "Enter") {
    this.newNegotiations();
  }
};

export const formatTimestamp = (timestamp) => {
  const options = {
    weekday: "long",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return timestamp.toLocaleTimeString("en-GB", options);
};
export const formatResponse = (res) => {
  if (res.data) {
    const getIds = Object.keys(res.data);
    const updateIds = Object.keys(res.data)
      .map((i, index) => {
        return { ...res.data[i], id: getIds[index] };
      })
      .reverse();
    const formattedArr = [...updateIds];
    return formattedArr;
  } else return [];
};
