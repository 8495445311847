import React from "react";
import Radium, { StyleRoot } from "radium";
import useRequest from "../../../hooks/useRequest";

const NotificationContainer = (props) => {
  const style = {
    card: {
      overflowY: "scroll",
      padding: "2rem",
      minWidth: "800px",
      minHeight: "500px",
      maxHeight: "550px",
      borderRadius: "1.5rem",
      backgroundColor: props.chatViewer ? "white" : "transparent",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      "@media (max-width: 770px)": {
        maxWidth: "100%",
        minWidth: "100%",
      },
    },
    clearAllDiv: {
      position: "sticky",
      top: "2px",
      left: "95%",
      border: "1px solid rgba(50, 50, 50, 0.1)",
      borderRadius: "10px",
      boxShadow: "0px 10px 10px 1px rgba(50, 50, 50, 0.3)",
      backgroundColor: "white",
      minHeight: "30px",
      minWidth: "60px",
      display: props.noItems ? "none" : "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  const { userID, reqSender } = useRequest();
  const clearAll = () => {
    reqSender(`https://peicst.com/api/notifications/${userID}`, "PATCH").then(
      (res) => {
        console.log(res);
        window.location.reload();
      }
    );
  };
  return (
    <StyleRoot>
      <div style={(props.style, style.card)}>
        <div style={style.clearAllDiv}>
          <small onClick={clearAll} className="clickableSmall">
            Clear All
          </small>
        </div>
        {props.noItems && (
          <small className="modalTitle">
            No new notifications at the moment, all clear!
          </small>
        )}
        {props.children}
      </div>
    </StyleRoot>
  );
};

export default Radium(NotificationContainer);
