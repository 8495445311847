import React, { useState } from "react";
import Radium, { StyleRoot } from "radium";

import MainButton from "../../../UI/MainButton/MainButton";
import IconWithToolTip from "../../../UI/icons/iconWithToolTip";
import CloseIcon from "../../../../assets/images/bin.png";

const NDADownloadWidget = (props) => {
  const [showDescription, setShowDescription] = useState(false);
  const style = {
    widgetContainer: {
      position: "absolute",
      top: "40%",
      left: "5px",
      zIndex: "700",
      width: "245px",
      height: "fit-content",
      backgroundColor: "white",
      borderRadius: ".5rem",
    },
  };
  const showOrHideDescription = (bool) => {
    setShowDescription(bool);
  };
  return (
    <StyleRoot>
      <div
        className={["flexCol", "fadeInAnimation"].join(" ")}
        style={style.widgetContainer}>
        <div style={{ alignSelf: "flex-end" }}>
          <IconWithToolTip
            src={CloseIcon}
            label="Close"
            click={props.showOrHideNDAWidget.bind(this, false)}
          />
        </div>
        {showDescription ? (
          <p className="modalText">
            This is a mutual confidentiality agreement that can cover cases
            where both parties will be disclosing confidential information as
            part of their trade. Confidentiality agreements, also known as
            non-disclosure agreements (NDAs), aim to ensure the information
            disclosed under the agreement remains confidential and kept secret,
            only being used for the purposes defined in the trade.
          </p>
        ) : (
          <small style={{ margin: "1rem" }} className="modalTitle">
            Want to discuss details in confidence? <br />
            <br />
            Click below to download and use an official confidentiality
            agreement or{" "}
            <span
              onClick={showOrHideDescription.bind(this, true)}
              style={{ color: "var(--p-pink)", cursor: "pointer" }}>
              {" "}
              click here{" "}
            </span>
            to read about how it works.
          </small>
        )}
        <a
          href="https://peicst.com/ConfidentialityAgreement.pdf"
          download
          target="_blank"
          rel="noopener noreferrer">
          {" "}
          <MainButton label="Download" />
        </a>
      </div>
    </StyleRoot>
  );
};

export default Radium(NDADownloadWidget);
