import React from "react";
import MainButton from "../../UI/MainButton/MainButton";
import Video from "../../../assets/images/pexels-johannes-plenio-1996035.jpg";
import { HashLink as Link } from "react-router-hash-link";
import Radium, { StyleRoot } from "radium";

const Home = (props) => {
  const style = {
    home: {
      position: "relative",
      margin: "0 auto",
      height: "40vh",
      width: "100vw",
      backgroundColor: "white",
      "@media (max-width: 770px)": {
        padding: "0 2rem",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "fit-content",
        textAlign: "center",
      },
    },
  };
  return (
    <StyleRoot>
      <section
        id="Home"
        style={{
          "@media (max-width: 770px)": {
            height: "fit-content",
          },
        }}>
        <div className="flexRow" style={style.home}>
          <div
            className="flexRow"
            style={{
              padding: "4rem",
              width: "85%",
              "@media (max-width: 770px)": {
                padding: "1rem",
                width: "100%",
                flexFlow: "column",
              },
            }}>
            <h2
              style={{
                width: "100%",
                fontSize: "3rem",
                "@media (max-width: 770px)": {
                  fontSize: "2.7em",
                },
              }}
              className="headings">
              Welcome to <span className="spanRed"> Peicst</span>.<br /> A place
              to trade value.
            </h2>
            <p className="text">
              <span className="tagLine">
                {" "}
                We believe anything is possible when people trade value instead
                of money.
              </span>
              <br></br>
              <br></br>
              Peicst offers a marketplace called Peicst It! where you can do
              just that. <br />
              You can trade anything you think is valuable on Peicst, from goods
              and services to skills and time, and easliy manage your trades at
              each stage. Peicst also offers free legal trade agreements so you
              can make your trades official. Let's Peicst it!
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: ".5rem",
              "@media (max-width: 770px)": {
                position: "static",
              },
            }}>
            <Link to="Landing#How it Works">
              <MainButton label="Learn More" />
            </Link>
          </div>
        </div>
      </section>
    </StyleRoot>
  );
};
export default Radium(Home);
