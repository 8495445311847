import React from "react";
import Radium, { StyleRoot } from "radium";

const NDAWidgetIcon = (props) => {
  const style = {
    widgetIcon: {
      position: "absolute",
      top: props.top,
      left: "0",
      zIndex: "700",
      width: "35px",
      backgroundColor: "white",
      borderRadius: ".5rem",
      transform: "rotate(180deg)",
      padding: "10px",
      cursor: "pointer",
      transition: ".5s ease",
      ":hover": {
        transform: "rotate(180deg) scale(1.1)",
      },
    },
  };
  return (
    <StyleRoot>
      <div onClick={props.click} className="flexCol" style={style.widgetIcon}>
        <small className="modalTitle" style={{ writingMode: "vertical-lr" }}>
          {props.label}
        </small>
      </div>
    </StyleRoot>
  );
};

export default Radium(NDAWidgetIcon);
