import React, { useState, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import useRequest from "../../../hooks/useRequest";

import NegotiationsItem from "./negotiationsItem";

const NegotationsContainer = (props) => {
	const [seenColorTransition, setseenColorTransition] = useState(false);
	const [isViewingReplies, setisViewingReplies] = useState(false);

	const { reqSender } = useRequest();

	const toggleViewReplies = (id) => {
		if (id) {
			setisViewingReplies(id);
		} else {
			setisViewingReplies(false);
		}
	};

	const onOfferAccepted = (data) => {
		console.log(props.loadedPeicst.id);
		props.isAccepting(data.recipientName);
		reqSender(`/api/chats/new/${props.loadedPeicst.id}`, "POST", data)
			.then((res) => {
				console.log(res);
				reqSender(`/api/negotiations/${data.id}`, "PATCH", null).then(() => {
					reqSender(
						`/api/posts/status/${props.loadedPeicst.id}`,
						"PATCH",
						JSON.stringify({
							data: "Trading",
						})
					);
				});
			})
			.catch((err) => {
				console.log(err);
				props.error();
			});
	};

	useEffect(() => {
		let seenArr = [];
		if (props.newContentSeen) {
			props.negotiations.forEach((i) => {
				if (!i.readByPoster) {
					seenArr.push(i.id);
				}
			});
			if (seenArr.length) {
				console.log(seenArr);
				setTimeout(() => {
					reqSender(
						`/api/negotiations/seen/${props.loadedPeicst.id}`,
						"PATCH",
						JSON.stringify({ seenArr })
					)
						.then((res) => {
							console.log(res);
							setseenColorTransition(true);
						})
						.catch((err) => console.log(err));
				}, 1500);
			}
		}
	}, [props.newContentSeen]);
	return (
		<StyleRoot>
			<div style={{ maxWidth: "100%" }}>
				{props.negotiations.length ? (
					props.negotiations.map((i, index) => {
						let children;
						if (i.children.length) {
							children = i.children
								.map((j) => {
									return (
										<NegotiationsItem
											date={j.timeStamp}
											id={j.id}
											key={j.id}
											avatar={j.authorImage}
											onIsDeleted={(u, m) =>
												props.isDeleting(
													`/api/negotiations/child/${i.id}/${j.id}`,
													"DELETE"
												)
											}
											author={j.authorName}
											authorId={j.authorId}
											doNotShow>
											{j.body}
										</NegotiationsItem>
									);
								})
								.reverse();
						}
						return (
							<React.Fragment key={index + i.id}>
								<NegotiationsItem
									date={i.timeStamp}
									id={i.id}
									key={i.id}
									author={i.authorName}
									authorId={i.authorId}
									avatar={i.authorImage}
									accepted={i.accepted}
									onIsDeleted={(u, m) =>
										props.isDeleting(`/api/negotiations/${i.id}`, "DELETE")
									}
									onShowReplies={(id) => toggleViewReplies(i.id)}
									onHideReplies={(id) => toggleViewReplies(null)}
									onOfferAccepted={(data) => onOfferAccepted(data)}
									isReplying={(id) => props.isReplying(id)}
									nodes={i.children}
									postId={props.loadedPeicst.id}
									offerTitle={props.loadedPeicst.oTitle}
									postAuthorId={props.loadedPeicst.authorId}
									colorSwitch={seenColorTransition}
									readByPoster={i.readByPoster}
									isViewingReplies={isViewingReplies}>
									{i.body}
								</NegotiationsItem>
								<div key={i.id + index + 5} style={{ marginLeft: "10%" }}>
									{isViewingReplies === i.id ? children : null}
								</div>
							</React.Fragment>
						);
					})
				) : (
					<h2 className="modalText" style={{ textAlign: "center" }}>
						Start Negotiations!
					</h2>
				)}
			</div>
		</StyleRoot>
	);
};

export default Radium(NegotationsContainer);
