import React from "react";
import Radium, { StyleRoot } from "radium";

const StatusIndicator = (props) => {
  if (props.loadedPeicst) {
    return (
      <StyleRoot>
        <h2
          className="status"
          style={[
            { marginLeft: "15px" },
            props.loadedPeicst.status === "Trading"
              ? { backgroundColor: "var(--p-pink)" }
              : props.loadedPeicst.status === "Complete"
              ? { backgroundColor: "var(--original-grey)" }
              : { backgroundColor: "var(--p-red)" },
          ]}>
          {props.loadedPeicst.status === "Trading"
            ? "Under Offer"
            : props.loadedPeicst.status === "Complete"
            ? "Complete"
            : "Trading"}
        </h2>
      </StyleRoot>
    );
  }
};
export default Radium(StatusIndicator);
