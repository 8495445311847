import TopSection from "../Headers/TopSection/TopSection";
import MainNav from "../Headers/MainNav/MainNav";
import React, { Component } from "react";
import Home from "../Landing/Home/Home";
import Works from "../Landing/Works/Works";
import ParallaxImage from "../UI/ParallaxImage/ParallaxImage";
import About from "../Landing/About/About";
import FAQs from "../Landing/FAQs/FAQs";
import Donate from "../Landing/Donate/Donate";
import Contact from "../Landing/Contact/Contact";
import Footer from "../Footers/footer";
import Radium, { StyleRoot } from "radium";
import Disclaimer from "../disclaimer/disclaimer";
import ImplicitConsentWidget from "../Policies/implicitConsentWidget";

class Landing extends Component {
	state = {
		showDisclaimer: false,
		showWidget: true,
	};
	navHandler = () => {
		this.props.history.push("/MarketPlace");
	};
	cancelDisclaimer = () => {
		this.setState({ showDisclaimer: false });
		localStorage.setItem("disclaimerSeen", true);
	};
	cancelWidget = () => {
		this.setState({ showWidget: false });
	};
	timer = () => {
		setTimeout(() => {
			this.setState({ showDisclaimer: true });
		}, 2000);
	};
	componentDidMount() {
		console.log(localStorage.getItem("disclaimerSeen"));
		if (
			!localStorage.getItem("disclaimerSeen") ||
			localStorage.getItem("disclaimerSeen") == "false"
		) {
			this.timer();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}
	render() {
		return (
			<StyleRoot>
				<div
					style={{
						position: "relative",
						"@media (max-width: 770px)": {
							overflowX: "hidden",
						},
					}}>
					<Disclaimer
						cancelDisclaimer={this.cancelDisclaimer}
						showDisclaimer={this.state.showDisclaimer}
					/>
					<TopSection logo="Peicst" />
					<MainNav click={this.navHandler} label="Browse Trades" />
					<ParallaxImage />
					<Home />
					<Works />
					<About />
					<FAQs />
					<Donate />
					<Contact />
					<Footer />
					{this.state.showWidget && (
						<ImplicitConsentWidget cancelWidget={this.cancelWidget} />
					)}
				</div>
			</StyleRoot>
		);
	}
}

export default Radium(Landing);
