import React, { useState, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import DetailsEditor from "../UI/detailsEditor";
import Spinner from "../../UI/Spinner/Spinner";
const EditableDetails = (props) => {
  const [isEditting, setisEditting] = useState(false);
  const [loading, setloading] = useState(false);

  const isEdittingToggle = (i) => {
    setisEditting(i);
  };
  const loadingToggle = () => {
    setloading(!loading);
  };
  const showNewContent = (state, data) => {
    props.showEditedData(state, data);
    setisEditting(false);
    setloading(false);
  };

  //remove ability to edit before isOwner check when parent updates
  useEffect(() => {
    setisEditting(false);
  }, [props.loadedPeicst]);

  const detailItems = props.loadedPeicst
    ? [
        ["Offer Title", "oTitle", props.loadedPeicst.oTitle],
        ["Offer Details", "oBody", props.loadedPeicst.oBody],
        ["Would Like In Return", "rBody", props.loadedPeicst.rBody],
      ]
    : null;
  if (props.loadedPeicst) {
    if (loading) {
      return <Spinner />;
    } else {
      return (
        <StyleRoot>
          <div
            className="flexCol"
            style={{
              maxWidth: "100%",
              margin: "0 1rem",
              padding: "1rem 3rem",
              alignItems: "flex-start",
              "@media (max-width: 770px)": {
                padding: "0",
              },
            }}>
            {detailItems.map((i) => {
              return (
                <React.Fragment key={`${i[0]}ParentFragment`}>
                  <h2 key={`${i[0]}Label`} className="modalTitle">
                    {" "}
                    {i[0]}
                  </h2>
                  {isEditting === i[1] ? (
                    <DetailsEditor
                      key={i[1]}
                      name={i[1]}
                      id={i[1]}
                      rows={i[1] === "oTitle" ? 1 : 5}
                      default={i[2]}
                      showNewContent={(state, data) =>
                        showNewContent(state, data)
                      }
                      loadingToggle={loadingToggle}
                      postId={props.loadedPeicst.id}
                      cancel={() => isEdittingToggle(false)}
                    />
                  ) : (
                    <React.Fragment key={`${i[0]}ChildFragment`}>
                      <p
                        className="modalText"
                        style={{ margin: "10px 0" }}
                        key={`${i[0]}Text`}>
                        {i[2]}
                      </p>
                      {props.userID === props.loadedPeicst.authorId && (
                        <small
                          key={`${i[0]}EditBtn`}
                          onClick={() => isEdittingToggle(i[1])}
                          className={["modalText", "clickableSmall"].join(" ")}
                          style={{ marginBottom: "10px" }}>
                          Edit
                        </small>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </StyleRoot>
      );
    }
  }
};
export default Radium(EditableDetails);
