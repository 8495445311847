import React, { useState } from "react";
import Radium, { StyleRoot } from "radium";
import useRequest from "../../../hooks/useRequest";

import ConfirmWindow from "../../UI/confirmWindow/confirmWindow";
import StatusIndicator from "../UI/statusIndicator";
import Save from "../../UI/icons/saveIcon";
import Delete from "../../UI/icons/deleteIcon";
import Reload from "../../UI/icons/iconWithToolTip";
import ReloadIcon from "../../../assets/images/icons8-refresh-30.png";

const Introduction = (props) => {
	const [confirmingDelete, setconfirmingDelete] = useState(false);
	const [confirmingReloadTrade, setconfirmingReloadTrade] = useState(false);
	const [peicstSaved, setpeicstSaved] = useState(false);
	const [peicstAlreadySaved, setpeicstAlreadySaved] = useState(false);
	const { reqSender, userID } = useRequest();

	const confirmReloadTradeToggle = () => {
		setconfirmingReloadTrade(!confirmingReloadTrade);
	};
	const confirmDeleteToggle = () => {
		setconfirmingDelete(!confirmingDelete);
	};
	const savePeicst = () => {
		reqSender(
			`/api/posts/favourites/${props.loadedPeicst.id}/${userID}`,
			"PATCH"
		)
			.then((res) => {
				setpeicstSaved(true);
				setTimeout(() => {
					setpeicstSaved(false);
				}, 1000);
			})
			.catch((err) => {
				if (err.response.status === 422) {
					setpeicstAlreadySaved(true);
					setTimeout(() => {
						setpeicstAlreadySaved(false);
					}, 1000);
				}
			});
	};
	const deletePost = () => {
		props.updateData(
			`/api/posts/${props.loadedPeicst.id}`,
			"DELETE",
			null,
			() => {
				window.location.reload();
			}
		);
	};
	const reloadPost = () => {
		props.updateData(
			`/api/posts/status/${props.loadedPeicst.id}`,
			"PATCH",
			JSON.stringify({
				data: "Live",
			})
		);
	};
	props.loadedPeicst && console.log(props.loadedPeicst.status);
	const reloaderWarning = (
		<React.Fragment>
			Are you sure you want to reload this trade?
			<br /> <br />
			This will revert your trade’s status back to ‘Trading’. <br />
			Please only use this option if your were unable to reach a final agreement
			with your accepted offer.
		</React.Fragment>
	);
	if (props.loadedPeicst) {
		return (
			<StyleRoot>
				<div
					className="flexRow"
					style={{
						maxWidth: "100%",
						margin: "2rem 1rem 1rem 1rem",
						padding: "2rem",
						justifyContent:
							confirmingDelete || confirmingReloadTrade
								? "center"
								: "space-between",
						"@media (max-width: 770px)": {
							padding: "0",
						},
					}}>
					{confirmingDelete ? (
						<ConfirmWindow
							text="Are you sure you want to delete this post?"
							confirmToggle={confirmDeleteToggle}
							update={deletePost}
						/>
					) : confirmingReloadTrade ? (
						<ConfirmWindow
							text={reloaderWarning}
							confirmToggle={confirmReloadTradeToggle}
							update={reloadPost}
						/>
					) : (
						<React.Fragment>
							<div className="flexCol" style={{ alignItems: "flex-start" }}>
								<StatusIndicator loadedPeicst={props.loadedPeicst} />
								<h2 className="modalTitle">
									Peicster:{" "}
									<span className="modalText">
										{props.loadedPeicst.authorName}
									</span>
									<span
										style={{ marginLeft: "20px" }}
										onClick={props.showingProfileToggle}
										className="clickableSmall">
										View Profile
									</span>
								</h2>

								<h2 className="modalTitle">
									Location:{" "}
									<span className="modalText">
										{props.loadedPeicst.location}
									</span>
								</h2>
								<h2 className="modalTitle">
									Posted On:{" "}
									<span className="modalText">
										{props.loadedPeicst.timeStamp}
									</span>
								</h2>
							</div>
							<div className="flexCol">
								{props.loadedPeicst.authorId === props.userID ? (
									<React.Fragment>
										<Delete click={confirmDeleteToggle} />
										{props.loadedPeicst.status === "Trading" ? (
											<Reload
												label="Reload Trade"
												src={ReloadIcon}
												click={confirmReloadTradeToggle}
											/>
										) : null}
									</React.Fragment>
								) : null}
								{peicstSaved ? (
									<small className="modalTitle">Saved!</small>
								) : peicstAlreadySaved ? (
									<small className="modalTitle">
										{" "}
										Already <br /> Saved!
									</small>
								) : (
									<Save click={savePeicst} />
								)}
							</div>
						</React.Fragment>
					)}
				</div>
			</StyleRoot>
		);
	}
};
export default Radium(Introduction);
