import React, { useState, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import useRequest from "../../../hooks/useRequest";
import Error from "../../UI/error";

const ProfileViewer = (props) => {
  const [profileImage, setprofileImage] = useState("");
  const [profileInfo, setprofileInfo] = useState({});
  const [error, seterror] = useState(false);

  const { reqSender, userID } = useRequest();

  useEffect(() => {
    if (props.loadedPeicst.authorId && userID)
      reqSender(
        `https://peicst.com/api/users/user/profile/${props.loadedPeicst.authorId}`,
        "GET"
      )
        .then((res) => {
          const { bio, location, skills, webOrComp } = res.data.user;
          setprofileInfo({
            "Bio:": bio,
            "Location:": location,
            "Skills:": skills,
            "Website/Company:": webOrComp,
          });
          setprofileImage(res.data.user.image);
        })
        .catch((err) => {
          seterror(true);
        });
  }, [userID]);

  const style = {
    goBackLabel: {
      margin: "1rem",
      position: "absolute",
      bottom: "0",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "@media (max-width: 770px)": {
        position: "static",
        transform: "translate(0%, 0%)",
      },
    },
    skillBubble: {
      minWidth: "6rem",
      width: "fit-content",
      minHeight: "1.8rem",
      maxHeight: "1.8rem",
      textAlign: "center",
      margin: "10px",
      padding: ".5rem",
      border: ".5px solid var(--p-grey)",
      color: "var(--p-grey)",
      borderRadius: "20rem",
      fontFamily: "var(--main-font)",
      fontSize: ".8em",
    },
  };
  if (error) {
    return <Error />;
  } else {
    return (
      <StyleRoot>
        <div
          className="flexRow"
          style={{
            width: "100%",
            alignItems: "flex-start",
            "@media (max-width: 770px)": {
              flexFlow: "column",
              alignItems: "center",
            },
          }}>
          {!userID ? (
            <h1
              style={{
                fontFamily: "var(--main-font)",
                fontSize: "2em",
                color: "var(--original-grey)",
                margin: "25% auto",
              }}>
              You must be logged in to see Peicster's profiles!
            </h1>
          ) : (
            <React.Fragment>
              <div
                className="flexRow"
                style={{
                  width: "30%",
                  height: "100%",
                  paddingTop: "10%",
                  "@media (max-width: 770px)": {
                    width: "100%",
                  },
                }}>
                <img
                  style={{
                    maxHeight: "200px",
                    maxWidth: "200px",
                    minHeight: "200px",
                    minWidth: "200px",
                    borderRadius: "1.5rem",
                    margin: "1rem",
                    objectFit: "cover",
                  }}
                  src={`https://peicst.com/${profileImage}`}
                  alt=""
                />
              </div>
              <div
                className="flexCol"
                style={{
                  width: "60%",
                  alignItems: "flex-start",
                  padding: "10% 1rem",
                  "@media (max-width: 770px)": {
                    alignItems: "center",
                    width: "100%",
                  },
                }}>
                {Object.entries(profileInfo).map(([key, val]) => {
                  return (
                    <React.Fragment key={`${key}viewProfileInfoHolder`}>
                      {!val || val == 0 ? null : (
                        <label
                          className="modalTitle"
                          key={`${key}viewProfileInfoLabel`}>
                          {key}
                        </label>
                      )}
                      {Array.isArray(val) && val.length ? (
                        <div
                          className="flexRow"
                          style={{
                            margin: "1.5rem 0",
                            flexWrap: "wrap",
                            "@media (max-width: 770px)": {
                              flexFlow: "column",
                            },
                          }}>
                          {val.map((j) => {
                            return (
                              <div
                                key={`${j}viewProfileSkillsDiv`}
                                style={style.skillBubble}>
                                {j}{" "}
                              </div>
                            );
                          })}{" "}
                        </div>
                      ) : (
                        <p
                          className="modalText"
                          style={{
                            margin: "1.5rem 0",
                          }}>
                          {val}
                        </p>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>{" "}
            </React.Fragment>
          )}
          <label
            onClick={props.showingProfileToggle}
            className="clickableSmall"
            style={style.goBackLabel}>
            Back to post
          </label>
        </div>
      </StyleRoot>
    );
  }
};

export default Radium(ProfileViewer);
