import React from "react";
import Radium, { StyleRoot } from "radium";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/actions";
const Login = (props) => {
	const style = {
		margin: "1rem",
		width: "fit-content",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly",
		cursor: "pointer",
		fontFamily: "var(--sub-font)",
		fontSize: ".9em",
		color: props.dark ? "var(--original-grey)" : "white",
		fontWeight: "bolder",
		"@media (max-width: 770px)": {},
	};
	const logNav = () => {
		props.history.push("/LoginPage");
	};
	const logOut = () => {
		props.onLogout();
		props.history.push("/LoginPage");
	};
	return (
		<StyleRoot>
			{props.userName ? (
				<h3 onClick={logOut} style={style}>
					{" "}
					Hi, {props.userName} | Logout
				</h3>
			) : (
				<h3 onClick={logNav} style={style}>
					Log In | Sign Up
				</h3>
			)}
		</StyleRoot>
	);
};

const mapStateToProps = (state) => {
	return { userName: state.userName, logout: state.logout };
};
const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => {
			dispatch(actions.explicitLogOut());
		},
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Radium(Login)));
