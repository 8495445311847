import React from "react";
import Radium, { StyleRoot } from "radium";
import { withRouter } from "react-router-dom";
import TopSection from "../Headers/TopSection/TopSection";
import MainNav from "../Headers/MainNav/MainNav";

const MainTerms = (props) => {
  const navHandler = () => {
    props.history.push("/MarketPlace");
  };
  return (
    <StyleRoot>
      <div
        style={{
          position: "relative",
          "@media (max-width: 770px)": {
            overflowX: "hidden",
          },
        }}>
        <TopSection logo="Peicst" />
        <MainNav click={navHandler} label="Browse Trades" />
        <div
          className="flexCol"
          style={{ position: "relative", top: "5rem", padding: "5rem" }}>
          <h1 className="headings" style={{ margin: "1rem" }}>
            Terms and Conditions
          </h1>
          <span className="text" style={{ textAlign: "center" }}>
            {" "}
            Please read the terms of this policy carefully before using the site{" "}
          </span>
          <p className="text">
            <br />
            <br />
            <span className="headings">What's in these terms?</span>
            <br />
            <br />
            This acceptable use policy sets out the content standards that apply
            when you upload content to our site, make contact with other users
            on our site, link to our site, or interact with our site in any
            other way,
            <br />
            <br />
            <span className="headings">
              Who we are and how to contact us:{" "}
            </span>{" "}
            <br />
            <br />
            Peicst.com is a site operated by PEICST LIMITED ("We"). We are
            registered in England and Wales under company number 07096204 and
            have our registered office at Offices A13-A14 Champions Business
            Park, Arrowe Brook Road, Wirral, Merseyside, United Kingdom, CH49
            0AB. Our main trading address is at Offices A13-A14 Champions
            Business Park, Arrowe Brook Road, Wirral, Merseyside, United
            Kingdom, CH49 0AB. We are a limited company.
            <br />
            To contact us, please email us at info@peicst.com.
            <br />
            <br />
            By using our site you accept these terms
            <br />
            By using our site, you confirm that you accept the terms of this
            policy and that you agree to comply with them.
            <br />
            If you do not agree to these terms, you must not use our site.
            <br />
            We recommend that you print a copy of these terms for future
            reference.
            <br />
            There are other terms that may apply to you
            <br />
            We may make changes to the terms of this policy
            <br />
            We amend these terms from time to time. Every time you wish to use
            our site, please check these terms to ensure you understand the
            terms that apply at that time. These terms were most recently
            updated on 17 July 2018.
            <br />
            <br />
            <span className="headings"> Prohibited uses</span>
            <br />
            <br />
            You may use our site only for lawful purposes. You may not use our
            site:
            <br />
            <br />
            • In any way that breaches any applicable local, national or
            international law or regulation.
            <br />
            • In any way that is unlawful or fraudulent, or has any unlawful or
            fraudulent purpose or effect.
            <br />
            • For the purpose of harming or attempting to harm minors in any
            way.
            <br />
            • To send, knowingly receive, upload, download, use or re-use any
            material which does not comply with our content standards.
            <br />
            • To transmit, or procure the sending of, any unsolicited or
            unauthorised advertising or promotional material or any other form
            of similar solicitation (spam).
            <br />
            • To knowingly transmit any data, send or upload any material that
            contains viruses, Trojan horses, worms, time-bombs, keystroke
            loggers, spyware, adware or any other harmful programs or similar
            computer code designed to adversely affect the operation of any
            computer software or hardware.
            <br />
            <br />
            You also agree:
            <br />
            <br />
            • Not to reproduce, duplicate, copy or re-sell any part of our site
            in contravention of the provisions of our terms of website use.
            <br />
            • Not to access without authority, interfere with, damage or
            disrupt:
            <br />
            • any part of our site;
            <br />
            • any equipment or network on which our site is stored; <br />
            • any software used in the provision of our site; or <br />
            • any equipment or network or software owned or used by any third
            party.
            <br />
            <br />
            <span className="headings"> Interactive services</span>
            <br />
            <br />
            We may from time to time provide interactive services on our site,
            including, without limitation:
            <br />
            • Chat rooms.
            <br />
            • Bulletin boards.
            <br />
            • Customer feedback.
            <br />
            • Questions and answers.
            <br />
            (interactive services.)
            <br />
            <br />
            Where we do provide any interactive service, we will provide clear
            information to you about the kind of service offered, if it is
            moderated and what form of moderation is used (including whether it
            is human or technical).
            <br />
            We will do our best to assess any possible risks for users (and in
            particular, for children) from third parties when they use any
            interactive service provided on our site, and we will decide in each
            case whether it is appropriate to use moderation of the relevant
            service (including what kind of moderation to use) in the light of
            those risks. However, we are under no obligation to oversee, monitor
            or moderate any interactive service we provide on our site, and we
            expressly exclude our liability for any loss or damage arising from
            the use of any interactive service by a user in contravention of our
            content standards, whether the service is moderated or not. <br />
            The use of any of our interactive services by a minor is subject to
            the consent of their parent or guardian. We advise parents who
            permit their children to use an interactive service that it is
            important that they communicate with their children about their
            safety online, as moderation is not fool proof. Minors who are using
            any interactive service should be made aware of the potential risks
            to them. <br />
            Where we do moderate an interactive service, we will normally
            provide you with a means of contacting the moderator, should a
            concern or difficulty arise.
            <br />
            <br />
            <span className="headings"> Content standards </span>
            <br />
            <br />
            These content standards apply to any and all material which you
            contribute to our site (Contribution), and to any interactive
            services associated with it. <br />
            The Content Standards must be complied with in spirit as well as to
            the letter. The standards apply to each part of any Contribution as
            well as to its whole.
            <br />
            PEICST LIMITED will determine, in its discretion, whether a
            Contribution breaches the Content Standards.
            <br />
            <br />
            A Contribution must:
            <br />
            • Be accurate (where it states facts).
            <br />
            • Be genuinely held (where it states opinions).
            <br />
            • Comply with the law applicable in England and Wales and in any
            country from which it is posted.
            <br />
            A Contribution must not:
            <br />
            • Be defamatory of any person.
            <br />
            • Be obscene, offensive, hateful or inflammatory.
            <br />
            • Promote sexually explicit material.
            <br />
            • Promote violence.
            <br />
            • Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
            <br />
            • Infringe any copyright, database right or trade mark of any other
            person.
            <br />
            • Be likely to deceive any person.
            <br />
            • Breach any legal duty owed to a third party, such as a contractual
            duty or a duty of confidence.
            <br />
            • Promote any illegal activity.
            <br />
            • Be in contempt of court.
            <br />
            • Be threatening, abuse or invade another's privacy, or cause
            annoyance, inconvenience or needless anxiety.
            <br />
            • Be likely to harass, upset, embarrass, alarm or annoy any other
            person.
            <br />
            • Impersonate any person, or misrepresent your identity or
            affiliation with any person.
            <br />
            • Give the impression that the Contribution emanates from PEICST
            LIMITED, if this is not the case.
            <br />
            • Advocate, promote, incite any party to commit, or assist any
            unlawful or criminal act such as (by way of example only) copyright
            infringement or computer misuse.
            <br />
            • Contain a statement which you know or believe, or have reasonable
            grounds for believing, that members of the public to whom the
            statement is, or is to be, published are likely to understand as a
            direct or indirect encouragement or other inducement to the
            commission, preparation or instigation of acts of terrorism.
            <br />
            • Contain any advertising or promote any services or web links to
            other sites.
            <br />
            <br />
            <span className="headings"> Breach of this policy </span>
            <br />
            <br />
            When we consider that a breach of this acceptable use policy has
            occurred, we may take such action as we deem appropriate. <br />
            Failure to comply with this acceptable use policy constitutes a
            material breach of the Peicst terms of use upon which you are
            permitted to use our site, and may result in our taking all or any
            of the following actions:
            <br />
            <br />
            • Immediate, temporary or permanent withdrawal of your right to use
            our site.
            <br />
            • Immediate, temporary or permanent removal of any Contribution
            uploaded by you to our site.
            <br />
            • Issue of a warning to you.
            <br />
            • Legal proceedings against you for reimbursement of all costs on an
            indemnity basis (including, but not limited to, reasonable
            administrative and legal costs) resulting from the breach.
            <br />
            • Further legal action against you.
            <br />
            • Disclosure of such information to law enforcement authorities as
            we reasonably feel is necessary or as required by law. We exclude
            our liability for all action we may take in response to breaches of
            this acceptable use policy. The actions we may take are not limited
            to those described above, and we may take any other action we
            reasonably deem appropriate.
            <br />
            <br />
            <span className="headings">
              {" "}
              Which country's laws apply to any disputes?{" "}
            </span>
            <br />
            <br />
            If you are a consumer, please note that the terms of this policy,
            its subject matter and its formation are governed by English law.
            You and we both agree that the courts of England and Wales will have
            exclusive jurisdiction except that if you are a resident of Northern
            Ireland you may also bring proceedings in Northern Ireland, and if
            you are resident of Scotland, you may also bring proceedings in
            Scotland.
            <br />
            <br />
            If you are a business, the terms of this policy, its subject matter
            and its formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the exclusive jurisdiction
            of the courts of England and Wales.
            <br />
            <br />
            <span className="headings"> Privacy Policy </span>
            <br></br> <br></br>
            Purpose of this privacy notice
            <br />
            <br />
            This privacy notice aims to give you information on how the Company
            collects and processes your personal data through your use of this
            website, including any data you may provide through this website
            when you sign up to our newsletter, purchase a product or service or
            take part in a competition. This website is not intended for
            children and we do not knowingly collect data relating to children.
            It is important that you read this privacy notice together with any
            other privacy notice or fair processing notice we may provide on
            specific occasions when we are collecting or processing personal
            data about you so that you are fully aware of how and why we are
            using your data. This privacy notice supplements the other notices
            and is not intended to override them. <br />
            <br />
            Controller <br />
            <br />
            PEICST LIMITED the controller and responsible for your personal data
            (collectively referred to as "COMPANY", "we", "us" or "our" in this
            privacy notice. <br />
            <br />
            We have appointed a data privacy manager who is responsible for
            overseeing questions in relation to this privacy notice. If you have
            any questions about this privacy notice, including any requests to
            exercise your legal rights, please contact the data privacy manager
            using the details set out below. <br />
            <br />
            Contact details <br />
            <br />
            Our full details are: <br></br>
            Full name of legal entity: PEICST LIMITED <br></br>
            Name or title: Data Privacy Manager <br></br>
            Email address: info@peicst.com <br></br>
            <br></br> <br></br>
            Postal address: <br></br>
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues (www.ico.org.uk). We would,
            however, appreciate the chance to deal with your concerns before you
            approach the ICO so please contact us in the first instance. Changes
            to the privacy notice and your duty to inform us of changes{" "}
            <br></br>
            This version was last updated on 17 July 2018. <br></br>
            The data protection law in the UK changed on 25 May 2018. Although
            this privacy notice sets out most of your rights under the new laws,
            we may not yet be able to respond to some of your requests as we are
            still working towards getting our systems ready for some of these
            changes. <br></br>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us. <br></br>
            Third-party links <br></br>
            This website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave our website, we encourage
            you to read the privacy notice of every website you visit. <br></br>{" "}
            <br />
            <br />
            <span className="headings"> The data we collect about you </span>
            <br />
            <br />
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together follows: <br></br>•
            Identity Data includes first name, maiden name, last name, username
            or similar identifier, marital status, title, date of birth and
            gender. <br></br>• Contact Data includes billing address, delivery
            address, email address and telephone numbers. • Financial Data
            includes bank account and payment card details. • Transaction Data
            includes details about payments to and from you and other details of
            products and services you have purchased from us. <br></br>•
            Technical Data includes internet protocol (IP) address, your login
            data, browser type and version, time zone setting and location,
            browser plug-in types and versions, operating system and platform
            and other technology on the devices you use to access this website.{" "}
            <br></br>• Profile Data includes your username and password,
            purchases or orders made by you, your interests, preferences,
            feedback and survey responses. <br></br>• Usage Data includes
            information about how you use our website, products and services. •
            Marketing and Communications Data includes your preferences in
            receiving marketing from us and our third parties and your
            communication preferences. <br></br>
            We also collect, use and share Aggregated Data such as statistical
            or demographic data for any purpose. Aggregated Data may be derived
            from your personal data but is not considered personal data in law
            as this data does not directly or indirectly reveal your identity.
            For example, we may aggregate your Usage Data to calculate the
            percentage of users accessing a specific website feature. However,
            if we combine or connect Aggregated Data with your personal data so
            that it can directly or indirectly identify you, we treat the
            combined data as personal data which will be used in accordance with
            this privacy notice. <br></br>
            We do not collect any Special Categories of Personal Data about you
            (this includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and
            genetic and biometric data). Nor do we collect any information about
            criminal convictions and offences. <br></br>
            If you fail to provide personal data <br></br>
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            goods or services). In this case, we may have to cancel a product or
            service you have with us but we will notify you if this is the case
            at the time. <br></br> <br />
            <br />
            <span className="headings">
              {" "}
              How is your personal data collected?{" "}
            </span>
            <br />
            <br />
            We use different methods to collect data from and about you
            including through: <br></br>• Direct interactions. You may give us
            your Identity, Contact and Financial Data by filling in forms or by
            corresponding with us by post, email or otherwise. This includes
            personal data you provide when you: <br></br>• apply for or use our
            products or services; <br></br>• apply for or use our products or
            services; <br></br>• create an account on our website; <br></br>•
            subscribe to our service or publications; <br></br>• request
            marketing to be sent to you; <br></br>• enter a competition,
            promotion or survey; or <br></br>• give us some feedback. <br></br>•
            Automated technologies or interactions. As you interact with our
            website, we may automatically collect Technical Data about your
            equipment, browsing actions and patterns. We collect this personal
            data by using cookies, server logs and other similar technologies.
            We may also receive Technical Data about you if you visit other
            websites employing our cookies. Please see our cookie policy for
            further details. <br></br>• Third parties or publicly available
            sources. We may receive personal data about you from various third
            parties and public sources as set out below: <br></br>• Technical
            Data from the following parties: <br></br>
            (a) analytics providers such as Google based outside the EU;{" "}
            <br></br>
            (b) advertising networks we may use from time to time which may be
            based inside or outside the EU; and <br></br>
            (c) search information providers based inside or the EU. <br></br>•
            Contact, Financial and Transaction Data from providers of technical,
            payment and delivery services based inside or outside the EU.{" "}
            <br></br>• Identity and Contact Data from data brokers or
            aggregators based inside or outside the EU. <br></br>• Identity and
            Contact Data from publicly availably sources such as Companies House
            and the Electoral Register based inside the EU.
            <br></br> <br />
            <br />
            <span className="headings"> How we use your personal data </span>
            <br />
            <br />
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances: <br></br>• Where we need to perform the contract we
            are about to enter into or have entered into with you. <br></br>•
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and <br></br> fundamental rights do
            not override those interests. <br></br>• Where we need to comply
            with a legal or regulatory obligation. <br></br>
            Generally we do not rely on consent as a legal basis for processing
            your personal data other than in relation to sending third party
            direct marketing communications to you via email or text message.
            You have the right to withdraw consent to marketing at any time by
            contacting us at marketing@peicst.com. <br></br>
            Purposes for which we will use your personal data <br></br>
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate. <br></br>
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more
            than one ground has been set out in the table below. <br></br>
            Purpose/Activity Type of data Lawful basis for processing including
            basis of legitimate interest <br></br>
            To register you as a new customer (a) Identity <br></br>
            (b) Contact Performance of a contract with you <br></br>
            To process and deliver your order including: <br></br>
            (a) Manage payments, fees and charges <br></br>
            (b) Collect and recover money owed to us (a) Identity <br></br>
            (b) Contact <br></br>
            (c) Financial <br></br>
            (d) Transaction <br></br>
            (e) Marketing and Communications (a) Performance of a contract with
            you <br></br>
            (b) Necessary for our legitimate interests (to recover debts due to
            us) <br></br>
            To manage our relationship with you which will include: <br></br>
            (a) Notifying you about changes to our terms or privacy policy{" "}
            <br></br>
            (b) Asking you to leave a review or take a survey (a) Identity{" "}
            <br></br>
            (b) Contact <br></br>
            (c) Profile <br></br>
            (d) Marketing and Communications (a) Performance of a contract with
            you <br></br>
            (b) Necessary to comply with a legal obligation <br></br>
            (c) Necessary for our legitimate interests (to keep our records
            updated and to study how customers use our products/services){" "}
            <br></br>
            To enable you to partake in a prize draw, competition or complete a
            survey (a) Identity <br></br>
            (b) Contact <br></br>
            (c) Profile <br></br>
            (d) Usage <br></br>
            (e) Marketing and Communications (a) Performance of a contract with
            you <br></br>
            (b) Necessary for our legitimate interests (to study how customers
            use our products/services, to develop them and grow our business){" "}
            <br></br>
            To administer and protect our business and this website (including
            troubleshooting, data analysis, testing, system maintenance,
            support, reporting and hosting of data) <br></br>
            (a) Identity <br></br>
            (b) Contact <br></br>
            (c) Technical (a) Necessary for our legitimate interests (for
            running our business, provision of administration and IT services,
            network security, to prevent fraud and in the context of a business
            reorganisation or group restructuring exercise) <br></br>
            (b) Necessary to comply with a legal obligation <br></br>
            To deliver relevant website content and advertisements to you and
            measure or understand the effectiveness of the advertising we serve
            to you <br></br>
            (a) Identity <br></br>
            (b) Contact <br></br>
            (c) Profile <br></br>
            (d) Usage <br></br>
            (e) Marketing and Communications <br></br>
            (f) Technical Necessary for our legitimate interests (to study how
            customers use our products/services, to develop them, to grow our
            business and to inform our marketing strategy) <br></br>
            To use data analytics to improve our website, products/services,
            marketing, customer relationships and experiences <br></br>
            (a) Technical <br></br>
            (b) Usage Necessary for our legitimate interests (to define types of
            customers for our products and services, to keep our website updated
            and relevant, to develop our business and to inform our marketing
            strategy) To make suggestions and recommendations to you about goods
            or services that may be of interest to you <br></br>
            (a) Identity <br></br>
            (b) Contact <br></br>
            (c) Technical <br></br>
            (d) Usage <br></br>
            (e) Profile <br></br>
            Necessary for our legitimate interests (to develop our
            products/services and grow our business) <br></br>
            Marketing <br></br> <br></br>
            We strive to provide you with choices regarding certain personal
            data uses, particularly around marketing and advertising. We have
            established the following personal data control mechanisms:{" "}
            <br></br>
            Promotional offers from us <br></br>
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which products, services
            and offers may be relevant for you (we call this marketing).{" "}
            <br></br>
            You will receive marketing communications from us if you have
            requested information from us or purchased goods or services from us
            [or if you provided us with your details when you entered a
            competition or registered for a promotion] and, in each case, you
            have not opted out of receiving that marketing. <br></br>
            Third-party marketing <br></br> <br></br>
            We will get your express opt-in consent before we share your
            personal data with any company outside the COMPANY group of
            companies for marketing purposes. <br></br>
            Opting out <br></br>
            You can ask us or third parties to stop sending you marketing
            messages at any time by following the opt-out links on any marketing
            message sent to you or by contacting us at any time. <br></br>
            Where you opt out of receiving these marketing messages, this will
            not apply to personal data provided to us as a result of a
            product/service purchase, warranty registration, product/service
            experience or other transactions. <br></br>
            Cookies <br></br>
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of this website may
            become inaccessible or not function properly. For more information
            about the cookies we use, please see our cookies policy. <br></br>
            Change of purpose <br></br>
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us. <br></br>
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so. Please note that we may process your personal data without
            your knowledge or consent, in compliance with the above rules, where
            this is required or permitted by law. <br></br> <br />
            <br />
            <span className="headings">
              {" "}
              Disclosures of your personal data{" "}
            </span>{" "}
            <br />
            <br />
            We may have to share your personal data with the parties set out
            below for the purposes set out in the table in paragraph 4 above.{" "}
            <br></br>• Internal Third Parties as set out in the Glossary.{" "}
            <br></br>• External Third Parties as set out in the Glossary.{" "}
            <br></br>• Specific third parties listed in the table in paragraph 4
            above. <br></br>• Third parties to whom we may choose to sell,
            transfer, or merge parts of our business or our assets.
            Alternatively, we may seek to acquire other businesses or merge with
            them. If a change happens to our business, then the new owners may
            use your personal data in the same way as set out in this privacy
            notice. <br></br>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.{" "}
            <br></br> <br />
            <br />
            <span className="headings"> International transfers </span>
            <br />
            <br />
            We do not transfer your personal data outside the European Economic
            Area (EEA). <br></br> <br />
            <br />
            <span className="headings"> Data security </span>
            <br />
            <br />
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality. We have put in place procedures to
            deal with any suspected personal data breach and will notify you and
            any applicable regulator of a breach where we are legally required
            to do so. <br></br> <br />
            <br />
            <span className="headings"> Data retention </span>
            <br />
            <br />
            How long will you use my personal data for? <br></br>
            We will only retain your personal data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.{" "}
            <br></br>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements. <br></br>
            By law we have to keep basic information about our customers
            (including Contact, Identity, Financial and Transaction Data) for
            six years after they cease being customers for tax purposes. In some
            circumstances you can ask us to delete your data: see Request
            erasure below for further information. In some circumstances we may
            anonymise your personal data (so that it can no longer be associated
            with you) for research or statistical purposes in which case we may
            use this information indefinitely without further notice to you.{" "}
            <br />
            <br /> <br />
            <br />
            <span className="headings"> Your legal rights </span>
            <br />
            <br />
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. Please click on the links
            below to find out more about these rights: <br></br>• Request access
            to your personal data. <br></br>• Request correction of your
            personal data. <br></br>• Request erasure of your personal data.{" "}
            <br></br>• Object to processing of your personal data. <br></br>•
            Request restriction of processing your personal data. <br></br>•
            Request transfer of your personal data. <br></br>• Right to withdraw
            consent. <br></br>
            If you wish to exercise any of the rights set out above, please
            contact us. <br></br>
            No fee usually required <br></br>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we may refuse to comply with your request
            in these circumstances. <br></br>
            What we may need from you <br></br>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response. <br></br>
            Time limit to respond <br></br>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated. <br></br> <br />
            <br />
            <span className="headings"> Glossary </span>
            <br />
            <br />
            LAWFUL BASIS <br></br>
            Legitimate Interest means the interest of our business in conducting
            and managing our business to enable us to give you the best
            service/product and the best and most secure experience. We make
            sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your
            personal data for our legitimate interests. We do not use your
            personal data for activities where our interests are overridden by
            the impact on you (unless we have your consent or are otherwise
            required or permitted to by law). You can obtain further information
            about how we assess our legitimate interests against any potential
            impact on you in respect of specific activities by contacting us.{" "}
            <br></br>
            Performance of Contract means processing your data where it is
            necessary for the performance of a contract to which you are a party
            or to take steps at your request before entering into such a
            contract. <br></br>
            Comply with a legal or regulatory obligation means processing your
            personal data where it is necessary for compliance with a legal or
            regulatory obligation that we are subject to. <br></br>
            THIRD PARTIES <br></br>
            External Third Parties <br></br>• Service providers acting as
            processors based in the United Kingdom who provide IT and system
            administration services. <br></br>• Professional advisers acting as
            processors or joint controllers including lawyers, bankers, auditors
            and insurers based in the United Kingdom who provide consultancy,
            banking, legal, insurance and accounting services. <br></br>• HM
            Revenue & Customs, regulators and other authorities acting as
            processors or joint controllers based in the United Kingdom who
            require reporting of processing activities in certain circumstances.{" "}
            <br></br> <br></br>
            YOUR LEGAL RIGHTS <br></br> <br></br>
            You have the right to: <br></br>
            Request access to your personal data (commonly known as a "data
            subject access request"). This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully
            processing it. Request correction of the personal data that we hold
            about you. This enables you to have any incomplete or inaccurate
            data we hold about you corrected, though we may need to verify the
            accuracy of the new data you provide to us. <br></br>
            Request erasure of your personal data. This enables you to ask us to
            delete or remove personal data where there is no good reason for us
            continuing to process it. You also have the right to ask us to
            delete or remove your personal data where you have successfully
            exercised your right to object to processing (see below), where we
            may have processed your information unlawfully or where we are
            required to erase your personal data to comply with local law. Note,
            however, that we may not always be able to comply with your request
            of erasure for specific legal reasons which will be notified to you,
            if applicable, at the time of your request. Object to processing of
            your personal data where we are relying on a legitimate interest (or
            those of a third party) and there is something about your particular
            situation which makes you want to object to processing on this
            ground as you feel it impacts on your fundamental rights and
            freedoms. You also have the right to object where we are processing
            your personal data for direct marketing purposes. In some cases, we
            may demonstrate that we have compelling legitimate grounds to
            process your information which override your rights and freedoms.
            Request restriction of processing of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in the following scenarios: (a) if you want us to establish the
            data's accuracy; (b) where our use of the data is unlawful but you
            do not want us to erase it; (c) where you need us to hold the data
            even if we no longer require it as you need it to establish,
            exercise or defend legal claims; or (d) you have objected to our use
            of your data but we need to verify whether we have overriding
            legitimate grounds to use it. <br></br>
            Request the transfer of your personal data to you or to a third
            party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you. <br></br>
            Withdraw consent at any time where we are relying on consent to
            process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent. <br></br>
          </p>
        </div>
      </div>
    </StyleRoot>
  );
};

export default withRouter(Radium(MainTerms));
