export const scrollToNegotiationsifOwner = (authorId, userID, ref) => {
  if (authorId === userID) {
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
  }
};

export const scrollTo = (ref) => {
  ref.current.scrollIntoView({
    behavior: "smooth",
  });
};
