import React from "react";
import MainButton from "../../UI/MainButton/MainButton";

const ConfirmWindow = (props) => {
	return (
		<div style={props.style}>
			<small className="red" style={{ fontFamily: "var(--main-font)" }}>
				{props.text}
			</small>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}>
				<MainButton click={props.update} label="Confirm" />
				<small
					onClick={props.confirmToggle}
					className={["modalText", "clickableSmall"].join(" ")}>
					Cancel
				</small>
			</div>
		</div>
	);
};

export default ConfirmWindow;
