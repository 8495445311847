import React, { Component } from "react";
import MainButton from "../../UI/MainButton/MainButton";
import MagniGlass from "../../../assets/images/magniGlass.png";
import Arrow from "../../../assets/images/arrow.png";
import Minus from "../../../assets/images/minus.png";
import Radium, { StyleRoot } from "radium";
import { HashLink as Link } from "react-router-hash-link";

class FAQs extends Component {
  state = {
    tabContentOne: false,
    tabContentTwo: false,
    tabContentFour: false,
    search: "",
  };

  //Toggle clicked tab and set all other tab states to false
  showHandler = (tab) => {
    const newState = {};
    Object.entries(this.state).forEach(([key, val]) => {
      if (key !== tab && key !== "_radiumStyleState" && key !== "search")
        return (newState[key] = false);
    });
    this.setState({ [tab]: !this.state[tab], ...newState });
  };

  render() {
    const style = {
      srchContainer: {
        margin: ".5rem",
        padding: "0",
        width: "60%",
        display: "flex",
        "@media (max-width: 770px)": {
          width: "90%",
        },
      },
      srchBtn: {
        margin: "auto",
        width: "2em",
        height: "2em",
        padding: "0",
        ":hover": {
          cursor: " pointer",
          transition: "ease-in-out",
          transform: "scale(1.2)",
        },
      },
      tabContainer: {
        position: "relative",
        margin: "0 auto",
        width: "60vw",
        padding: "0 1rem",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flexFlow: "column",
        "@media (max-width: 770px)": {
          width: "100%",
        },
      },
      tab: {
        maxWidth: "50%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 10px 10px 0px rgba(50, 50, 50, 0.3)",
        border: "1.5px solid var(--original-grey)",

        ":hover": {
          cursor: "pointer",
          backgroundColor: "var(--p-pink)",
          color: " white",
        },
      },
      tabContent: {
        textAlign: "justify",
        fontFamily: "var(--sub-font)",
        fontSize: "0.75em",
        color: "var(--p-sub-grey)",
        lineHeight: "1.5",
        fontWeight: "bold",
      },
      arrow: {
        width: "16px",
        transition: "all .5s ease-in-out ",
      },
    };

    let one = (
      <div
        style={this.state.tabContentOne ? null : { display: "none" }}
        id="tab-content">
        <p className="text">
          Peicst provides several features to help you get value trading with
          ease. Below is a breakdown of each process from sign up to completing
          a trade-off, with step by step guides on how to use each feature.
          <br /> <br /> <span className="faqsHeading">How to Sign Up:</span>
          <br />
          <br />
          To start using the site features you need to create an accout.
          <br /> <br />
          Step 1: Navigate to the Login page by clicking on "Log In or Sign Up"
          in the top right corner of the header.
          <br />
          <br />
          Step 2: Complete the sign up form by providing a user name, an email
          address and a password. You will be asked to repeat your password
          once. Please use a mix of uppercase and lowercase letters and numbers
          to ensure your password strength A user dashboard where you can see
          and manage your trades and saved trades
          <br /> <br />
          Step 3: You will then be asked to agree to our terms of use and
          privacy policy to continue. A copy of the policy will be available to
          you on the screen, please make sure you read and understand this. You
          can also read all our terms including our data policy, cookie policy
          and privacy policy by clicking on "Terms & Conditions" in the footer
          of this site. Once you've agreed please click on 'Submit'. You will
          then be redirected to your dashboard.
          <br /> <br />
          <span className="faqsHeading">How to Use the Dashboard:</span>
          <br /> <br />
          Once you've signed up you will be redirected to your dashboard. This
          area of the site is also available to navigate to whenever you're
          logged in. Your dashboard is where you can create or update your
          profile, see and manage your trade-offs and and any trades you've
          saved for later.
          <br /> Your dashboard also contains all your chats with other
          Peicster's, the chat function becomes available once you've made an
          offer on someone's Peicst or accepted an offer on a Peicst you have
          created. - A negotiations platform on each trade that works like a
          commenting system, where Peicsters can bid on offers with
          counter-offers or ask the Peicster questions.
          <br /> <br />
          The first page you'll see on your dashboard is your profile page.{" "}
          <br />
          Completing your profile is optional but highly encouraged as letting
          other Peicsters know a bit about you is great way to build trust. You
          can complete some or all of it, so don't worry about filling in every
          field right away. Follow the steps below to complete your profile.
          <br /> <br />
          Step 1: Upload an image. This will be your profile picture and appear
          on your communications with other Peicsters throughout the site. You
          can click on 'Choose Image' to upload a file from your computer, then
          click on 'Save' to submit it.
          <br /> <br />
          Step 2: Write a short bio. This can be a brief and freindly
          introduction about yourself. You can add up to a 1000 characters worth
          of text in your bio. You can save your profile at this point by
          clicking 'Save' at the bottom of the form, or continue with the other
          feilds.
          <br /> <br />
          Step 3: Your location. If you start typing your location in the
          location feild suggestions will appear below it. Please select your
          location from one of the suggestions Again, you may wish to save your
          profile here, or continue with the last feild. <br /> <br />
          Step 4: Your Website or Company name. If you have a website or own a
          company that you would like to share with other Peicsters here, please
          enter the link or name in this feild. <br /> <br />
          Step 5: Save your profile by clicking on the 'Save' button at the
          bottom of the form.
          <br /> <br />
          The other areas in your dashboard can be navigated to using the side
          menu on the left handside of the screen.
          <br /> <br />
          • 'My Trades' is where you can find all trades that you've created,
          see their status and access them for commenting, deleting or
          monitoring.
          <br /> <br />
          • 'My favourites' is where you can find other Peicsters trades that
          you've saved. Details on how to save trades can be found in our 'How
          to use Peicst it! ' section below.
          <br /> <br />
          • 'My Chats' is where you can access all your current and past chats
          with other Peicsters. This feature becomes available when you accept
          an offer on a trade-off or an offer that you've made is accepted by
          another Peicster.
          <br /> <br />
          <span className="faqsHeading">How to Use Peicst It! :</span>
          <br /> <br />
          You can access Peicst It! by clicking on 'Browse Trades' located in
          the left corner of the navigation bar. You will then be able to see
          all the trades that exist on Peicst It! <br /> <br />
          There are three components to help guide you through the offers:
          <br /> <br />
          1) An image the Peiscter has uploaded <br />
          <br />
          2) A status indicator bar marked as either 'Trading', 'Under Offer',
          or 'Complete'.
          <br />
          <br />
          3) A brief description of the offer.
          <br /> <br />
          • If a trade is indicated as 'Trading', this means the Peicster has
          not yet accepted any offers. <br />
          • If it is indicated to be 'Under Offer', it means the Peicster has
          accepted an offer, but you can still view the trade-off. <br />
          • If it is indicated as 'Complete', it means a deal has been
          completed. You will still be able to view the offer for a short time.
          with a brief discription of the offer and an image the Peicster has
          uploaded.
          <br /> <br />
          If you like the sound of an offer you can click on 'See More'. This
          will generate a pop up window with all the details of the offer, all
          the images the Peicster has uploaded and a link to the Peicster's
          profile, if they have one.
          <br />
          To view the Peicster's profile, you can click on the 'View Profile'
          link located near the Peicster's name. Please note that you must be
          logged in to view Peicster's profiles.
          <br />
          If you would like to save the offer for later, you can click on the
          heart icon located in the right corner of the Peicst. The offer will
          then appear in the 'My Favourites' section in your dashboard.
          <br />
          <br />
          <span className="faqsHeading">
            {" "}
            Negotiations and making an offer:
          </span>
          <br />
          <br />
          Offers are made by clicking on the 'Negotiate' button at the bottom of
          the window. You can view all current negotiations and the Peiscter's
          replies by scrolling down to the 'Negotiations' section. <br />
          <br />
          If you would like to make an offer or ask a question about the
          trade-off, you can do this by clicking on 'Negotiate' and typing in
          the feild that appears, then subitting it. Your offer or question will
          then appear on the trade-off, if you change your mind, you can delete
          it by clicking on the 'x' icon in the right corner. <br />
          <br />
          The Peicster will be able to reply to your comment and/or accept your
          offer. You will be notified via email and through your account if they
          do. If your offer is accepted, you and the Peicster will be able to
          move to a private chat to discuss the details of your deal. Details of
          how the private chat works can be found below, in our 'Private Chat
          and Documents' section.
          <br />
          <br />
          <span className="faqsHeading">Creating A Trade:</span>
          <br />
          <br />
          Once you are in Peicst It! , you can create a trade of your own by
          clicking on 'Create Trade' in the left most corner of the navigation
          bar. A form will then pop up with the following feilds:
          <br /> <br /> 1) An option to upload an image or images. <br />
          2) A location feild. <br />
          3) A feild labeled 'Your Offer Details'
          <br />
          4) A feild labeled 'Quick Summary'
          <br />
          5) A feild labeled 'What would you like in return?'
          <br />
          <br />
          To complete the form, follow the 5 steps below:
          <br />
          <br />
          Step 1: Upload an image or images to highlight your trade. If your
          offer isn't about an object that can be photographed, you may use any
          related image or images that visually convey your offer.
          <br />
          <br />
          Step 2: Type your location into the location feild and select it from
          the list of suggestions below the feild. The location of your offer is
          important and will make your offer more accessible to people using
          location to seacrh for offers.
          <br /> <br />
          Step 3: Give as much detail and background as you like about your
          offer in the 'Your Offer Details' feild. This can include your reasons
          for Peicsting your offer, any relevant dates, if it is time
          sensistive, or anything else that you think is important. <br />{" "}
          <br />
          Step 4: Give a brief summary of your offer, preferably in a couple of
          sentences. This is what people will see when they are browsing offers,
          before they look at the details.
          <br />
          <br />
          Step 5: Talk about what you would like in return in the last feild.
          You can be as detailed or open as you'd like here. Peicsting is about
          value, which can mean different things to different people. Think
          about what would be valuable to you in exchange for what you are
          offering. <br /> <br />
          You may now submit your offer by clicking 'Peicst It!' at the bottom
          of the form. <br /> Your offer will then appear in the Peicst .
          <br /> <br />
          <span className="faqsHeading">Accepting an Offer:</span>
          <br /> <br />
          Once you have Peicsted you offer, you can view all negotiations on it,
          reply to them and/or accept an offer.
          <br /> <br />
          To do this, access your trade-off via your dashboard or the Peicst and
          scroll down to the negotiaitons. On each negotiaition, you will see an
          option labelled 'Accept Offer'. Click on this to accept an offer. The
          negotiator will then be notified and you will then be shown a screen
          advising you that you may now move to a private chat. You can only
          accept one offer on each trade-off. Once you have accepted an offer,
          the 'Accept Offer' option will stop being available on other
          negotiations on your trade-off. <br /> <br />
          You are encouraged to use negotiations as an opportunity to turn your
          offer or counter offer into something tangible and equal in value or
          significance! Use Peicst it! to negotiate with peicstee/s and realise
          a dream or an extraordinary opportunity, it could be the trade-off of
          a lifetime!
          <br /> <br />
          <span className="faqsHeading">
            Private chat and accessing the documents:
          </span>
          <br /> <br />
          Once you have accepted an offer or an offer you have made has been
          accepted by another Peicster, a private chat will become available to
          you and the other Peicster.
          <br /> <br />
          To access your chats you can navigate to the 'My Chats' section via
          your dashboard. If you are accessing the chat feature for the first
          time, you will be asked to complete a quick questionaire before you
          can continue.
          <br /> <br />
          All and any data you put in this form is completely anonymous and only
          used by Peicst to monitor and improve our services, and make Peicst
          better tailored to our users.
          <br /> <br />
          Once you have completed the questionaire, all your chats will be
          accessible and you can enagage with, delete or view a chat from within
          the 'My Chats' section.
          <br />
          If a user sends you a message while you are offline, you will be
          notified via email.
          <br /> <br />
          Once you have agreed on a deal via private chat, you can click on
          'Make Deal' in the bottom right corner of the chat window. You will
          then have full access to downloadable copies of official trade
          agreements.
          <br /> <br />
          To learn about the different trade agreements you can use, please
          visit our 'Trade Agreements' tab on this page.
          <br /> <br />
        </p>
      </div>
    );
    let two = (
      <div
        style={this.state.tabContentTwo ? null : { display: "none" }}
        id="tab-content">
        <p className="text">
          Peicst provides free official trade agreements to help you trade with
          peace of mind. <br /> These become available once you've clicked on
          trade-off in your chat window. You can download and edit the documents
          to suit your specific terms.
          <br />
          <br />
          <span className="faqsHeading">Barter Agreement:</span>
          <br />
          This is a standard agreement that covers most cases and simply makes
          the terms of your exchange official.
          <br /> <br />
          <span className="faqsHeading">
            Online Business and Consumer Goods Services:
          </span>
          <br />
          This covers cases where professional goods or services are being
          exchanged, and can be used along side the Barter Agreement if you
          like. <br />
          Use this if you are exchanging your own professional products or
          services, or when working on long term projects together based on a
          value exchange.
          <br />
          <br />
          <span className="faqsHeading">Confidentiality Agreement</span>
          <br />
          This is a mutual confidentiality agreement that can cover cases where
          both parties will be disclosing confidential information as part of
          their trade. Confidentiality agreements, also known as non-disclosure
          agreements (NDAs), aim to ensure the information disclosed under the
          agreement remains confidential and kept secret, only being used for
          the purposes defined in the trade.
        </p>
      </div>
    );
    let four = (
      <div
        style={this.state.tabContentFour ? null : { display: "none" }}
        id="tab-content">
        <p className="text">
          We take all reasonable steps to ensure that our Website is available
          24 hours every day. However, websites do sometimes encounter downtime
          due to server and, other technical issues. Therefore we will not be
          liable if our Website is unavailable at any time. <br /> <br /> The
          documents provided are general sample templates. <br />
          The provision of these sample documents (and the documents themselves)
          do not constitute legal advice or opinions of any kind, or any
          advertising or solicitation. <br /> <br />
          No lawyer-client, advisory, fiduciary or other relationship is created
          between PEICST LIMITED (''PEICST'') and any person accessing or
          otherwise using any of the sample documents. PEICST and its affiliates
          (and any of their respective directors, officers, agents, contractors,
          interns, suppliers and employees) will not be liable for any damages,
          losses or causes of action of any nature arising from any use of any
          of the sample documents or the provision of these sample documents.
        </p>
      </div>
    );
    const tabs = [
      ["tabContentOne", "Documents and Features", one],
      ["tabContentTwo", "Trade agreements", two],
      ["tabContentFour", "Disclaimer", four],
    ];

    return (
      <StyleRoot>
        <section
          id="FAQs"
          className="section"
          style={{
            borderTopRightRadius: "2rem",
            borderTopLeftRadius: "2rem",
            height: "fit-content",
            "@media (max-width: 770px)": {
              borderRadius: "0",
            },
          }}>
          <div
            className="textContainer"
            style={{
              marginTop: "5rem",
              "@media (max-width: 770px)": {
                margin: "0",
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                minWidth: "90%",
              },
            }}>
            <h2 className="headings">
              FAQ
              <span className="spanRed">s</span>
            </h2>
            <p
              className="text"
              style={{
                marginLeft: "2rem",
                "@media (max-width: 770px)": {
                  margin: "auto",
                },
              }}>
              <span className="tagLine">How can we help?</span>
              <br></br> <br></br>
              Search or read our detailed documents about how Peicst works. Our
              FAQ's will be reviewed in consideration of your feedback and
              frequently asked questions.
            </p>
          </div>
          <div style={style.srchContainer}>
            <img src={MagniGlass} style={style.srchBtn} alt="submit" />
            <input
              key="FAQssearchBar"
              className="formInput"
              type="search"
              placeholder="Search our docs using keywords..."
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
          </div>
          <div style={style.tabContainer}>
            {tabs
              .filter((tab) => {
                return tab[2].props.children.props.children
                  .toString()
                  .toLowerCase()
                  .includes(this.state.search.toLowerCase());
              })
              .map((i, index) => {
                return (
                  <React.Fragment key={i[0] + 1}>
                    <div
                      key={i[0] + index}
                      style={
                        this.state[i[0]]
                          ? [style.tab, style.tab[":hover"]]
                          : style.tab
                      }
                      className={["tab", "formInput"].join(" ")}
                      onClick={(tab) => this.showHandler(i[0])}>
                      {i[1]}
                      <img
                        className="plus"
                        style={
                          this.state[i[0]]
                            ? [style.arrow, { filter: "invert(1)" }]
                            : style.arrow
                        }
                        src={this.state[i[0]] ? Minus : Arrow}
                        alt=""
                      />
                    </div>
                    {i[2]}
                  </React.Fragment>
                );
              })}
          </div>
          <br /> <br />
          <Link to="Landing#Contact">
            <MainButton label="Contact Us" />
          </Link>
        </section>
      </StyleRoot>
    );
  }
}

export default Radium(FAQs);
