import React, { useState, useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import SocketLayer from "./socketLayer";

const ChatController = (props) => {
  const [tradeDetails, setTradeDetails] = useState({});
  const [showCongratsView, setShowCongratsView] = useState(false);
  const [showNDAWidget, setShowNDAWidget] = useState(false);
  const [showTradeAgreementWidget, setShowTradeAgreementWidget] = useState(
    false
  );
  const [initMsgs, setInitMsgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { userID, reqSender } = useRequest();

  useEffect(() => {
    setLoading(true);
    if (props.id) {
      reqSender(`/api/chats/${props.id}`, "GET")
        .then((res) => {
          const patchLastMsgAsSeen =
            res.data.chat.messages[res.data.chat.messages.length - 1].id;
          reqSender(
            `/api/chats/add/${props.id}/${userID}/${patchLastMsgAsSeen}`,
            "PATCH"
          ).catch((err) => setError(true));
          setInitMsgs(res.data.chat.messages);
          setTradeDetails({
            title: res.data.chat.offerTitle,
            reference: res.data.chat.offerRef,
          });
          setLoading(false);
          setTimeout(() => {
            setShowNDAWidget(true);
          }, 1000);
        })
        .catch((err) => {
          setError(true);
        });
    }
    return takeUserOffline();
  }, [props.id]);

  const takeUserOffline = (cb) => {
    reqSender(`/api/chats/remove/${props.id}/${userID}`, "PATCH")
      .then((res) => {
        setLoading(false);
        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        setError(true);
      });
  };
  const patchTradeStatus = () => {
    reqSender(
      `/api/posts/status/${tradeDetails.reference}`,
      "PATCH",
      JSON.stringify({
        data: "Complete",
      })
    ).catch((err) => {
      setError(true);
    });
  };
  const showOrHideCongratsView = (bool) => {
    if (bool) {
      patchTradeStatus();
      setShowCongratsView(true);
    } else {
      setShowCongratsView(false);
    }
  };
  const showOrHideNDAWidget = (bool) => {
    setShowNDAWidget(bool);
    if (bool && showTradeAgreementWidget) {
      setShowTradeAgreementWidget(false);
    }
  };
  const showOrHideTradeAgreementWidget = (bool) => {
    setShowTradeAgreementWidget(bool);
    if (bool && showNDAWidget) {
      setShowNDAWidget(false);
    }
  };
  const changeLoadingView = (bool) => {
    setLoading(bool);
  };
  const showErrorView = () => {
    setError(true);
  };
  const patchUserStatusAndExit = () => {
    takeUserOffline(props.cancel);
  };

  return (
    <SocketLayer
      loading={loading}
      error={error}
      initMsgs={initMsgs}
      chatId={props.id}
      chatTitle={tradeDetails.title}
      showCongratsView={showCongratsView}
      showNDAWidget={showNDAWidget}
      showTradeAgreementWidget={showTradeAgreementWidget}
      showOrHideNDAWidget={showOrHideNDAWidget}
      showOrHideTradeAgreementWidget={showOrHideTradeAgreementWidget}
      showErrorView={showErrorView}
      changeLoadingView={changeLoadingView}
      showErrorView={showErrorView}
      showOrHideCongratsView={showOrHideCongratsView}
      patchUserStatusAndExit={patchUserStatusAndExit}
    />
  );
};

export default ChatController;
