import React, { useState } from "react";
import MainButton from "../../UI/MainButton/MainButton";
import Radium from "radium";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Spinner from "../../UI/Spinner/Spinner";
import Success from "../../UI/success";
import Error from "../../UI/error";

const Contact = (props) => {
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const [success, setsuccess] = useState(false);
  const style = {
    margin: "auto",
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexFlow: "column",
    fontFamily: "var(--sub-font)",
    width: "700px",
    height: "700px",
    "@media (max-width: 770px)": {
      width: "100%",
      height: "fit-content",
    },
  };

  const req = Yup.string().trim("Required").required("Required");
  const validationSchema = Yup.object({
    name: req,
    email: Yup.string()
      .required("Required")
      .email("Please enter a valid email"),
    subject: req,
    message: req,
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    onSubmit: (values) => {
      setloading(true);
      let url;
      if (values.subject === "info") {
        url = "/api/mail/info";
      } else if (values.subject === "Marketing") {
        url = "/api/mail/marketing";
      } else if (values.subject === "Donation") {
        url = "/api/mail/donate";
      } else if (values.subject === "Reporting") {
        url = "/api/mail/report";
      }
      axios
        .post(url, values)
        .then((res) => {
          formik.resetForm();
          setloading(false);
          setsuccess(true);
          setTimeout(() => {
            setsuccess(false);
          }, 1000);
        })
        .catch((err) => seterror(true));
    },
    validationSchema,
  });

  return (
    <section
      id="Contact"
      className="section"
      style={{
        "@media (max-width: 770px)": {
          height: "fit-content",
        },
      }}
    >
      {error ? (
        <Error />
      ) : loading ? (
        <Spinner />
      ) : success ? (
        <Success />
      ) : (
        <form onSubmit={formik.handleSubmit} style={style}>
          <h2 style={{ alignSelf: "center" }} className="headings">
            {" "}
            Contact<span className="spanRed"> Us</span>
          </h2>
          <label className="modalTitle" htmlFor="name">
            Name
          </label>
          <input
            key="msg1"
            className="formInput"
            type="text"
            autoComplete="off"
            id="name"
            name="name"
            required
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <small style={{ color: "var(--p-red)" }}>
              {formik.errors.name}
            </small>
          ) : null}

          <label className="modalTitle" htmlFor="email">
            Email
          </label>
          <input
            key="msg2"
            className="formInput"
            type="text"
            name="email"
            autoComplete="off"
            id="email"
            required
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <small style={{ color: "var(--p-red)" }}>
              {formik.errors.email}
            </small>
          ) : null}

          <label className="modalTitle" htmlFor="select">
            Query
          </label>
          <select
            name="subject"
            id="subject"
            className={["formInput", "formSelect"].join(" ")}
            required
            {...formik.getFieldProps("subject")}
          >
            <option value="info">Information request</option>
            <option value="Marketing">Marketing query</option>
            <option value="Donation">Donation query</option>
            <option value="Reporting">Report an Issue</option>
          </select>
          {formik.touched.subject && formik.errors.subject ? (
            <small style={{ color: "var(--p-red)" }}>
              {formik.errors.subject}
            </small>
          ) : null}
          <label className="modalTitle" htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            className={["formInput", "formTextArea"].join(" ")}
            rows="5"
            autoComplete="off"
            required
            {...formik.getFieldProps("message")}
          />
          {formik.touched.message && formik.errors.message ? (
            <small style={{ color: "var(--p-red)" }}>
              {formik.errors.message}
            </small>
          ) : null}

          <div style={{ alignSelf: "center" }}>
            <MainButton
              type="submit"
              label="Send"
              click={formik.handleSubmit}
            />
          </div>
        </form>
      )}
    </section>
  );
};

export default Radium(Contact);
