import React, { useState, useRef, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import useRequest from "../../hooks/useRequest";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Helpers from "./helpers";

import Spinner from "../UI/Spinner/Spinner";
import MainButton from "../UI/MainButton/MainButton";
import Error from "../UI/error";
import Success from "../UI/success";

import CongratsScreen from "../UI/congratsScreen/congratsScreen";

import DynamicInput from "./UI/dynamicInput";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import Introduction from "./01/introduction";
import EditableDetails from "./02/editableDetails";
import NegotiationsContainer from "./03/negotiationsContainer";
import ProfileViewer from "./UI/profileViewer";

const ExpandedPeicst = (props) => {
	//data states
	const [loadedPeicst, setloadedPeicst] = useState(null);
	const [negotiations, setnegotiations] = useState([]);
	const [images, setimages] = useState([]);

	//user action states
	const [isNegotiating, setisNegotiating] = useState(false);
	const [isReplying, setisReplying] = useState(false);

	//user action toggles
	const isNegotiatingToggle = () => {
		setisNegotiating(!isNegotiating);
	};
	const isReplyingToggle = (id) => {
		if (id) {
			setisReplying(id);
		} else {
			setisReplying(false);
		}
	};

	// current view states
	const [showingProfile, setshowingProfile] = useState(false);
	const [showingCongratsScreen, setshowingCongratsScreen] = useState(false);
	const [showingLoginToNegotiate, setshowingLoginToNegotiate] = useState(false);

	//current view toggles
	const showingProfileToggle = () => {
		setshowingProfile(!showingProfile);
	};
	const showingCongratsScreenToggle = (userName) => {
		if (userName) {
			setshowingCongratsScreen(userName);
		} else {
			reqSender(`/api/posts/${props.id}`, "GET", null).then((res) => {
				setData(res);
				setshowingCongratsScreen(false);
			});
		}
	};
	const logInToNegotiateTimer = () => {
		setTimeout(() => {
			setshowingLoginToNegotiate(false);
		}, 3000);
	};
	const showingLoginToNegotiateToggle = () => {
		setshowingLoginToNegotiate(true);
		logInToNegotiateTimer();
	};

	//special UI states
	const [newContentSeen, setnewContentSeen] = useState(false);

	//standard UI states
	const [loading, setloading] = useState(false);
	const [success, setsuccess] = useState(false);
	const [error, seterror] = useState(false);

	const errorHandler = () => {
		seterror(true);
	};

	//Refs
	const topRef = useRef();
	const negotiationsRef = useRef();

	const { reqSender, userID } = useRequest();

	//State changing helpers

	//Format and set data on mount and sequential updates
	const setData = (res) => {
		setloadedPeicst(res.data.post);
		setnegotiations(Object.values(res.data.post.negotiations).reverse());
		setimages(res.data.post.image);
		setisReplying(false);
		setisNegotiating(false);
		setloading(false);
	};

	//Perform user CRUDs, get and set latest data and scroll to display it
	const updateData = (url, method, data, cb) => {
		setloading(true);
		reqSender(url, method, data)
			.then((res) => {
				if (cb) return cb();
				reqSender(`/api/posts/${props.id}`, "GET", null).then((res) => {
					setData(res);
					if (negotiationsRef.current) Helpers.scrollTo(negotiationsRef);
				});
			})
			.catch((err) => {
				console.log(err);
				seterror(true);
			});
	};

	const showEditedData = (state, data) => {
		setloadedPeicst({ ...loadedPeicst, [state]: data });
	};

	useEffect(() => {
		if (props.id) {
			setloading(true);
			if (!loadedPeicst || (loadedPeicst && loadedPeicst.id !== props.id)) {
				reqSender(`/api/posts/${props.id}`, "GET")
					.then((res) => {
						setData(res);
						topRef.current.scrollIntoView();
					})
					.catch((err) => {
						seterror(true);
						console.log(err);
					});
			}
		}
		return () => {
			clearTimeout(logInToNegotiateTimer);
		};
	}, [props.id]);

	//Show user new negotiations content and let negotiations cmp know content is seen
	useEffect(() => {
		if (loadedPeicst) {
			if (loadedPeicst.authorId === userID) {
				setTimeout(() => {
					if (negotiationsRef.current)
						negotiationsRef.current.scrollIntoView({
							behavior: "smooth",
						});
				}, 500);
				setnewContentSeen(true);
			}
		}
	}, [loadedPeicst]);

	if (loading) {
		return <Spinner />;
	} else if (error) {
		return <Error />;
	} else if (success) {
		return <Success />;
	} else if (showingProfile) {
		return (
			<ProfileViewer
				loadedPeicst={loadedPeicst}
				showingProfileToggle={showingProfileToggle}
			/>
		);
	} else if (showingCongratsScreen) {
		return (
			<CongratsScreen back={() => showingCongratsScreenToggle(null)}>
				You've accepted {showingCongratsScreen}'s offer! <br></br> You can now
				move to a private chat and iron out the details of your trade. <br></br>{" "}
				You can find your chats in your dashboard, once you're happy to seal the
				deal you'll be able to click through straight from the chat and access
				our official trade documents if you need them. Good Luck!
			</CongratsScreen>
		);
	} else {
		return (
			<StyleRoot>
				<React.Fragment>
					<div style={{ maxWidth: "100%" }} ref={topRef} />
					{images.length > 1 ? (
						<AwesomeSlider className="expandedSlider">
							{images.map((i) => {
								return (
									<div
										key={`${i}AwsSliderImage`}
										data-src={`https://peicst.com/${i}`}></div>
								);
							})}
						</AwesomeSlider>
					) : (
						<img
							style={{
								width: "100%",
								maxHeight: "28rem",
								objectFit: "cover",
								"@media (max-width: 770px)": {
									maxHeight: "32rem",
								},
							}}
							src={`https://peicst.com/${images[0]}`}
							alt="image"
						/>
					)}
					<Introduction
						userID={userID}
						showingProfileToggle={showingProfileToggle}
						loadedPeicst={loadedPeicst}
						updateData={updateData}
					/>
					<EditableDetails
						loadedPeicst={loadedPeicst}
						userID={userID}
						showEditedData={(state, data) => showEditedData(state, data)}
					/>
					<div ref={negotiationsRef} />
					<h2
						style={{
							textAlign: "left",
							margin: " 2rem 1rem 1rem 1rem",
							paddingLeft: "2rem",
						}}
						className="modalTitle">
						Negotiations: {negotiations.length}{" "}
					</h2>
					<NegotiationsContainer
						negotiations={negotiations}
						isReplying={(id) => isReplyingToggle(id)}
						isDeleting={updateData}
						isAccepting={(userName) => showingCongratsScreenToggle(userName)}
						newContentSeen={newContentSeen}
						loadedPeicst={loadedPeicst}
						error={errorHandler}
					/>
					{isReplying && (
						<DynamicInput
							url={`/api/negotiations/child/${isReplying}`}
							cancel={() => isReplyingToggle(null)}
							updateData={updateData}
							parentId={loadedPeicst.id}
							placeholder="Reply to negotiations..."
						/>
					)}
					{isNegotiating && (
						<DynamicInput
							url={"/api/negotiations"}
							cancel={isNegotiatingToggle}
							updateData={updateData}
							parentId={loadedPeicst.id}
							placeholder="Make an offer! 
                If your offer is accepted, you will be able to move to a private chat to iron out the details..."
						/>
					)}
					{isNegotiating || isReplying ? null : (
						<div
							className={[
								"sticky",
								(loadedPeicst && loadedPeicst.status === "Complete") ||
								(loadedPeicst && loadedPeicst.status === "Trading")
									? "flexCol"
									: "flexRow",
							].join(" ")}
							style={{ height: "80px" }}>
							{showingLoginToNegotiate ? (
								<Link to="/LoginPage">
									<small className={["modalTitle", "clickableSmall"].join(" ")}>
										You must be logged in to negotiate! Click here to sign up or
										log in.
									</small>
								</Link>
							) : (
								<React.Fragment>
									{loadedPeicst && loadedPeicst.status === "Complete" ? (
										<small className="modalTitle">
											This trade-off is complete!
										</small>
									) : loadedPeicst && loadedPeicst.status === "Trading" ? (
										<small className="modalTitle">
											Negotations are suspended while a trade-off is under
											offer.
										</small>
									) : (
										<MainButton
											click={
												props.isAuth
													? isNegotiatingToggle
													: showingLoginToNegotiateToggle
											}
											label="Negotiate"
										/>
									)}

									<small
										onClick={props.goBack}
										className={["modalText", "clickableSmall"].join(" ")}>
										Back
									</small>
								</React.Fragment>
							)}
						</div>
					)}
				</React.Fragment>
			</StyleRoot>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		userName: state.userName,
		userId: state.userId,
		isAuth: state.isAuth,
		image: state.image,
	};
};
export default connect(mapStateToProps)(Radium(ExpandedPeicst));
