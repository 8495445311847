import React from "react";

const success = (props) => {
  return (
    <h1
      style={{
        fontFamily: "var(--main-font)",
        fontSize: "2em",
        color: "var(  --p-pink)",
        margin: "25% auto",
      }}
    >
      Success!
    </h1>
  );
};

export default success;
