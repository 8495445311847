import React from "react";
import Hands from "../../../assets/images/red.jpg";
import { Parallax } from "react-parallax";
import Radium, { StyleRoot } from "radium";

const ParallaxImage = (props) => (
  <StyleRoot>
    <Parallax
      style={{ backgroundColor: "white" }}
      bgImageStyle={{
        width: "100%",
        objectFit: "cover",
        "@media (maxWidth: 770px)": {
          objectFit: "scale-down",
        },
      }}
      blur={0}
      bgImage={Hands}
      bgImageAlt="Background"
      strength={600}>
      <div
        style={{
          height: "50vh",
          "@media (max-width: 770px)": {
            display: props.hide && "none",
            height: "40vh",
          },
        }}
      />
    </Parallax>
  </StyleRoot>
);
export default Radium(ParallaxImage);
