import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as Util from "../../../store/utility";
import Logo from "../../UI/Logo/Logo";
import MainButton from "../../UI/MainButton/MainButton";
import Modal from "../../UI/Modal/Modal";
import CongratsScreen from "../../UI/congratsScreen/congratsScreen";
import Radium, { StyleRoot } from "radium";
import Spinner from "../../UI/Spinner/Spinner";
import ChatMsgBubble from "./UI/chatMsgBubble";
import NDADownloadWidget from "./components/NDADownloadWidget";
import TradeAgreementWidget from "./components/tradeAgreementsWidget";
import NDAWidgetIcon from "./UI/widgetIcon";
import FileUploader from "./components/fileUploader";

const ChatAPIView = (props) => {
  const bottomRef = useRef();
  const scrollToLastMsg = () => {
    Util.scroll(bottomRef);
  };
  useEffect(() => {
    if (bottomRef.current) {
      scrollToLastMsg();
    }
  }, [bottomRef.current]);
  useEffect(() => {
    if (bottomRef.current) scrollToLastMsg();
  }, [props.msgs]);
  const style = {
    window: {
      overflowY: "scroll",
      backgroundColor: "white",
      width: "95%",
      height: "70%",
      margin: "0 auto",
      padding: "1rem",
      "@media (max-width: 770px)": {
        maxWidth: "100%",
        overflowX: "hidden",
        height: "80vh",
      },
    },
    actionsContainer: {
      width: "90%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 770px)": {
        flexDirection: "row-reverse",
      },
      inputsContainer: {
        width: "95%",
        justifyContent: "space-evenly",
        margin: "0 auto",
        padding: "1rem",
      },
    },
    titleHeader: {
      position: "fixed",
      top: "0",
      width: "100%",
      padding: "10px",
      backgroundColor: "white",
      minHeight: "55px",
    },
  };
  const initCancel = () => {
    props.changeLoadingView(true);
    props.initDisconnectSocket();
  };
  const emitAndScrollToLast = (e) => {
    props.emitAndPersistMsg(e);
  };

  if (props.showCongratsView) {
    return (
      <Modal
        show={props.showCongratsView}
        click={props.showOrHideCongratsView.bind(this, false)}>
        <CongratsScreen
          download
          back={props.showOrHideCongratsView.bind(this, false)}>
          You have made a trade-off! <br></br>You can now access our official
          trade agreements to complete your trade-off!<br></br>
          Download links are below.
        </CongratsScreen>
      </Modal>
    );
  } else if (props.loading || !props.msgs) {
    return (
      <Modal show>
        <Spinner />
      </Modal>
    );
  } else {
    return (
      <StyleRoot>
        {props.showNDAWidget ? (
          <NDADownloadWidget showOrHideNDAWidget={props.showOrHideNDAWidget} />
        ) : (
          <NDAWidgetIcon
            top="40%"
            label="NDA Download"
            click={props.showOrHideNDAWidget.bind(this, true)}
          />
        )}
        {props.showTradeAgreementWidget ? (
          <TradeAgreementWidget
            showOrHideTradeAgreementWidget={
              props.showOrHideTradeAgreementWidget
            }
          />
        ) : (
          <NDAWidgetIcon
            top="15%"
            label="Trade Agreements"
            click={props.showOrHideTradeAgreementWidget.bind(this, true)}
          />
        )}

        <Modal click={initCancel} show>
          <div className="flexCol" style={style.window}>
            <div className="flexCol" style={style.titleHeader}>
              <Logo logo="Peicst" />{" "}
              <small className="modalTitle">Chat Re: {props.chatTitle}</small>
            </div>
            {props.msgs.map((i, idx) => {
              return (
                <div
                  key={`chatBubbleWrapper${idx}`}
                  style={
                    i.authorId === props.userId
                      ? {
                          alignSelf: "flex-end",
                        }
                      : {
                          alignSelf: "flex-start",
                        }
                  }>
                  <ChatMsgBubble
                    userId={props.userId}
                    authorId={i.authorId}
                    timeStamp={i.timeStamp}
                    body={i.body}
                    file={i.file}
                    authorName={i.authorName}
                  />
                </div>
              );
            })}
            <div style={{ marginTop: "2.5rem" }} ref={bottomRef} />
          </div>
          <div style={style.inputsContainer} className="flexRow">
            <form style={{ width: "90%" }} className="flexCol">
              <textarea
                name="bio"
                id="placeholder"
                className={["formInput", "formTextArea"].join(" ")}
                rows="5"
                value={props.newMsg}
                onChange={props.createNewMsg}
              />
              <div style={style.actionsContainer}>
                <div className="flexRow">
                  <MainButton
                    type="button"
                    label="Trade Off"
                    click={props.showOrHideCongratsView.bind(this, true)}
                  />
                  <label className="clickableSmall" onClick={initCancel}>
                    {" "}
                    Exit Chat
                  </label>
                </div>

                <div>
                  <label
                    className="clickableSmall"
                    onClick={(e) => emitAndScrollToLast(e)}>
                    {" "}
                    Send Message{" "}
                  </label>
                </div>
              </div>
            </form>
            <FileUploader shareFile={(file) => props.shareFile(file)} />
          </div>
        </Modal>
      </StyleRoot>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.isAuth,
    userId: state.userId,
    userName: state.userName,
    image: state.image,
  };
};

export default connect(mapStateToProps)(Radium(ChatAPIView));
