import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Send from "../../UI/icons/sendIcon";
import Radium from "radium";
import * as Utility from "../../../store/utility";
import { connect } from "react-redux";

const DynamicInput = (props) => {
  const validationSchema = Yup.object({
    body: Yup.string()
      .trim("Oops, can't send an empty form!")
      .required("Oops, can't send an empty form!")
      .max(720, "Exceeding max characters, 500 or less."),
  });
  const formik = useFormik({
    initialValues: { body: "" },
    onSubmit: (values) => {
      const data = JSON.stringify({
        parent: props.parentId,
        authorName: props.userName,
        authorImage: props.image,
        authorId: props.userId,
        timeStamp: Utility.formatTimestamp(new Date()),
        body: values.body,
        readByPoster: "false",
      });
      props.updateData(props.url, "POST", data);
    },
    validationSchema,
  });
  return (
    <div className="sticky">
      <textarea
        {...formik.getFieldProps("body")}
        name="body"
        id="body"
        key="dynamicIpnut"
        className={["formInput", "formTextArea"].join(" ")}
        placeholder={props.placeholder}
        rows="5"
      />
      {formik.touched.body && formik.errors.body ? (
        <small style={{ color: "var(--p-red)" }}>{formik.errors.body}</small>
      ) : null}
      <div className="flexRow">
        <Send click={formik.handleSubmit} />
        <small
          onClick={props.cancel}
          className={["modalText", "clickableSmall"].join(" ")}>
          Back
        </small>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userName: state.userName,
    userId: state.userId,
    image: state.image,
  };
};
export default connect(mapStateToProps)(Radium(DynamicInput));
