import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import useRequest from "../../../hooks/useRequest";
import * as Util from "../../../store/utility";
import ChatAPIView from "./chatAPIView";

const SocketLayer = (props) => {
  const [msgs, setMsgs] = useState([]);
  const [newMsg, setNewMsg] = useState("");
  const socketRef = useRef(null);
  const { userID, reqSender } = useRequest();
  const token = props.isAuth;

  useEffect(() => {
    if (props.initMsgs) setMsgs(props.initMsgs);
  }, [props.initMsgs]);
  useEffect(() => {
    socketRef.current = io("", {
      query: { token },
    });
    socketRef.current.on("connection", console.log("SOCKET CONNECTED"));
    socketRef.current.emit("USER_CONNECTED", userID);
    socketRef.current.on("MESSAGE", (msg) => {
      setMsgs((msgs) => [...msgs, msg]);
    });

    return () => {
      socketRef.current.emit(
        "USER_DISCONNECTED",
        userID,
        console.log(`SOCKET DISCONNECTED`)
      );
    };
  }, [userID, token]);

  const disconnectSocketAndInitPatch = () => {
    socketRef.current.emit(
      "USER_DISCONNECTED",
      userID,
      console.log(`SOCKET DISCONNECTED`)
    );
    props.patchUserStatusAndExit();
  };

  let lastMsg, assessedRecipient, configuredMsg;
  if (msgs.length > 0) {
    lastMsg = msgs.length - 1;
    assessedRecipient =
      msgs[lastMsg].authorId === userID
        ? msgs[lastMsg].recipient
        : msgs[lastMsg].authorId;

    configuredMsg = {
      recipient: assessedRecipient,
      authorId: userID,
      timeStamp: Util.formatTimestamp(new Date()),
      authorName: props.userName,
      body: newMsg,
      authorImage: props.image,
    };
  }

  const emitAndPersistMsg = (e) => {
    e.preventDefault();
    reqSender(`/api/chats/${props.chatId}`, "POST", configuredMsg).catch(
      (err) => {
        console.error(err);
        props.showErrorView();
      }
    );
    socketRef.current.emit("SEND_MESSAGE", assessedRecipient, configuredMsg);
    setMsgs((msgs) => [...msgs, configuredMsg]);
    setNewMsg("");
  };

  const sendFileHTTPOnly = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("recipient", assessedRecipient);
      formData.append("authorId", userID);
      formData.append("timeStamp", Util.formatTimestamp(new Date()));
      formData.append("authorName", props.userName);
      formData.append("file", file);
      formData.append("body", "File_Sharing");
      formData.append("authorImage", props.image);
      reqSender(`/api/chats/${props.chatId}`, "POST", formData)
        .then((res) => {
          resolve();
          const msgReplicant = {};
          for (var entry of formData.entries()) {
            msgReplicant[entry[0]] = entry[1];
          }
          setMsgs([...msgs, msgReplicant]);
        })
        .catch((err) => props.showErrorView());
    });
  };

  /*const emitOnKeyEnter = (e) => {
    if (e.key === "Enter") {
      emitAndPersistMsg(e);
    }
  };*/
  const createNewMsg = (e) => {
    setNewMsg(e.target.value);
  };

  return (
    <ChatAPIView
      chatTitle={props.chatTitle}
      error={props.error}
      newMsg={newMsg}
      msgs={msgs}
      userID={userID}
      loading={props.loading}
      error={props.error}
      showErrorView={props.showErrorView}
      showCongratsView={props.showCongratsView}
      showNDAWidget={props.showNDAWidget}
      showTradeAgreementWidget={props.showTradeAgreementWidget}
      changeLoadingView={props.changeLoadingView}
      showErrorView={props.showErrorView}
      showOrHideCongratsView={props.showOrHideCongratsView}
      showOrHideNDAWidget={props.showOrHideNDAWidget}
      showOrHideTradeAgreementWidget={props.showOrHideTradeAgreementWidget}
      createNewMsg={createNewMsg}
      emitAndPersistMsg={emitAndPersistMsg}
      initDisconnectSocket={disconnectSocketAndInitPatch}
      shareFile={sendFileHTTPOnly}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.isAuth,
    userName: state.userName,
    image: state.image,
  };
};

export default connect(mapStateToProps)(SocketLayer);
