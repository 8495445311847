import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flexRow" style={{ width: "100vw", height: "100vh" }}>
          <h1
            style={{
              fontFamily: "var(--main-font)",
              fontSize: "2em",
              color: "var(--original-grey)",
            }}>
            Oops, something went wrong! <br />
            <br />
            Please refresh the page...
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
