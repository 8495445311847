import React from "react";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import { connect } from "react-redux";
import Modal from "../UI/Modal/Modal";
import LoginForm from "../forms/LoginPage/LoginForm";

const sessionTimeOut = (props) => {
	const navigateAwayAndCloseModal = () => {
		props.history.push("/home");
		return props.onIgnoreSessinTimeOut();
	};

	return (
		<Modal show>
			<div style={{ width: "100%", height: "100%" }} className="flexCol">
				<small className="modalTitle"> For security reasons </small>
				<LoginForm seshTimeOut closeModal={navigateAwayAndCloseModal} />
			</div>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onIgnoreSessinTimeOut: () => {
			dispatch(actions.ignoreSessionTimeOut());
		},
	};
};

export default withRouter(connect(null, mapDispatchToProps)(sessionTimeOut));
