import React, { Component, Suspense } from "react";
import "./App.css";
import Landing from "../components/Landing/Landing";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Radium, { StyleRoot } from "radium";
import * as actions from "../store/actions/actions";
import Spinner from "../components/UI/Spinner/Spinner";
import ErrorBoundary from "../containers/ErrorBoundary/ErrorBoundary";
import axios from "axios";
import NotificationPage from "../components/Notification/NotificationPage/notificationPage";
import MainTerms from "../components/Policies/mainTerms";
import SessionTimeOut from "../components/sessionTimeOut/sessionTimeOut";
import PassResetForm from "../components/forms/LoginPage/PassResetForm";

const MarketPlace = React.lazy(() =>
	import("../components/Marketplace/MarketPlace")
);
const LoginPage = React.lazy(() =>
	import("../components/forms/LoginPage/LoginPage")
);
const MyDash = React.lazy(() =>
	import("../components/Landing/myDash/controller/dashBoardController")
);
window.onbeforeunload = function (event) {
	localStorage.removeItem("disclaimerSeen");
};
class App extends Component {
	componentDidMount() {
		const logData = JSON.parse(localStorage.getItem("userData"));
		if (logData && logData.token) {
			this.props.onAuthSignIn(
				logData.userId,
				logData.userName,
				logData.token,
				logData.expi,
				logData.image,
				logData.feedback,
				logData.bio
			);
		}
		if (logData && logData.token) {
			axios({
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${logData.token}`,
				},
				url: `/api/users/user/inbox/${logData.userId}`,
			})
				.then((res) => {
					this.props.onUpdateInbox(res.data.notifyInbox);
				})
				.catch((err) => console.log(err));
		}
	}

	render() {
		return (
			<StyleRoot>
				<ErrorBoundary>
					<BrowserRouter>
						{this.props.sessionTimeOut && <SessionTimeOut />}
						<Switch>
							<Route path="/Landing" exact component={Landing} />
							<Route
								path="/MarketPlace"
								render={() => (
									<Suspense fallback={<Spinner />}>
										<MarketPlace />
									</Suspense>
								)}
							/>
							<Route
								path="/LoginPage"
								render={() => (
									<Suspense fallback={<Spinner />}>
										<LoginPage />
									</Suspense>
								)}
							/>

							<Route
								path="/MyDash"
								render={() => (
									<Suspense fallback={<Spinner />}>
										<MyDash />
									</Suspense>
								)}
							/>

							<Route
								path="/Notification"
								render={() => (
									<Suspense fallback={<Spinner />}>
										<NotificationPage />
									</Suspense>
								)}
							/>
							<Route
								path="/MainTerms"
								render={() => (
									<Suspense fallback={<Spinner />}>
										<MainTerms />
									</Suspense>
								)}
							/>
							<Route path="/Reset/:jwt" component={PassResetForm} />

							<Redirect from="/" to="/Landing" />
						</Switch>
					</BrowserRouter>
				</ErrorBoundary>
			</StyleRoot>
		);
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		onAuthSignIn: (id, userName, token, expi, image, feedback, bio) =>
			dispatch(
				actions.authSuccess(id, userName, token, expi, image, feedback, bio)
			),
		onUpdateInbox: (notifyInbox) => dispatch(actions.updateInbox(notifyInbox)),
	};
};
const mapStateToProps = (state) => {
	return {
		isAuth: state.isAuth,
		userId: state.userId,
		sessionTimeOut: state.sessionTimeOut,
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Radium(App));
