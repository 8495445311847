import React from "react";
import Aux from "../../../HOCs/Aux/Aux";
import Radium, { StyleRoot } from "radium";
import Logo from "../../UI/Logo/Logo";
import Login from "./Login/Login";
import { withRouter } from "react-router-dom";

const TopSection = (props) => {
  const style = {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "100",
    margin: "0",
    backgroundColor: "white",
    minWidth: "100%",
    height: "6vh",
    justifyContent: "space-between",
    padding: "0 2rem",
    "@media (max-width: 770px)": {
      display: props.location.pathname === "/MyDash" ? "none" : "flex",
      padding: ".5rem 1rem",
      height: "10vh",
      maxWidth: "100vw",
    },
  };
  return (
    <Aux>
      <StyleRoot>
        <div className="flexRow" style={style}>
          <Logo logo={props.logo} />
          <Login dark />
        </div>
      </StyleRoot>
    </Aux>
  );
};
export default withRouter(Radium(TopSection));
