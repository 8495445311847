import * as actionTypes from "./actionTypes";
import axios from "axios";

let logoutTimer;

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START,
	};
};

export const logout = () => {
	localStorage.removeItem("userData");
	clearTimeout(logoutTimer);
	return {
		type: actionTypes.AUTH_LOGOUT,
	};
};
export const explicitLogOut = () => {
	localStorage.removeItem("userData");
	clearTimeout(logoutTimer);
	return {
		type: actionTypes.AUTH_LOGOUT,
	};
};
export const sessionTimeOut = () => {
	localStorage.removeItem("userData");
	clearTimeout(logoutTimer);
	return {
		type: actionTypes.AUTH_SESH_TIMEOUT,
	};
};
export const ignoreSessionTimeOut = () => {
	return {
		type: actionTypes.AUTH_IGNORE_SESH_TIMEOUT,
	};
};

export const logoutRedirect = () => {
	setTimeout(() => {
		return {
			type: actionTypes.CLEAR_REDIRECT,
		};
	}, 2000);
};
export const updateInbox = (notifyInbox) => {
	return {
		type: actionTypes.SET_INBOX,
		notifyInbox,
	};
};
export const updateBio = (bio) => {
	const update = JSON.parse(localStorage.getItem("userData"));
	update["bio"] = bio;
	localStorage.setItem("userData", JSON.stringify(update));
	return {
		type: actionTypes.SET_BIO,
		bio,
	};
};
export const authSuccess = (
	id,
	userName,
	token,
	expi,
	image,
	feedback,
	bio,
	notifyInbox
) => {
	if (new Date(expi) > new Date()) {
		return {
			type: actionTypes.AUTH_SUCCESS,
			id,
			userName,
			token,
			image,
			feedback,
			bio,
			notifyInbox,
		};
	} else {
		return sessionTimeOut();
	}
};
export const authFail = (err, source) => {
	if (source === "login") {
		return {
			type: actionTypes.AUTH_FAIL,
			errorLogIn: err,
		};
	} else {
		return {
			type: actionTypes.AUTH_FAIL,
			errorSignUp: err,
		};
	}
};
export const authenticate = (values, source) => {
	return (dispatch) => {
		dispatch(authStart(values));
		let url = "/api/users/signUp";
		if (source === "login") url = "/api/users/logIn";

		axios({
			method: "POST",
			url: url,
			headers: { "Content-Type": "application/json" },
			data: JSON.stringify({
				userName: values.userName,
				email: values.email,
				password: values.password,
			}),
		})
			.then((res) => {
				const { id, userName, feedback, image, notifyInbox, bio } =
					res.data.user;
				const token = res.data.token;
				const expi = new Date(new Date().getTime() + 1000 * 60 * 60);
				dispatch(
					authSuccess(
						id,
						userName,
						token,
						expi,
						image,
						feedback,
						bio,
						notifyInbox
					)
				);
				localStorage.setItem(
					"userData",
					JSON.stringify({
						userId: id,
						token,
						userName,
						expi: expi.toISOString(),
						image,
						feedback,
						bio,
					})
				);
				const cntdwn = expi.getTime() - new Date().getTime();
				logoutTimer = setTimeout(() => {
					sessionTimeOut();
				}, cntdwn);
			})
			.catch((err) => {
				console.log(err);
				const error = err.message;
				dispatch(authFail(error, source));
			});
	};
};
