import React from "react";
import Radium, { StyleRoot } from "radium";
import Send from "../../../assets/images/send.png";

const sendIcon = (props) => {
  return (
    <StyleRoot>
      <span onClick={props.click} data-text="Send" className="toolTip">
        <img className="icons" src={Send} alt="Save" />
      </span>
    </StyleRoot>
  );
};

export default Radium(sendIcon);
