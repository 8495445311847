import React from "react";
import Radium, { StyleRoot } from "radium";
import Reply from "../../../assets/images/reply.png";

const replyIcon = (props) => {
  return (
    <StyleRoot>
      <span onClick={props.click} data-text="Reply" className="toolTip">
        <img className="icons" src={Reply} alt="delete" />
      </span>
    </StyleRoot>
  );
};

export default Radium(replyIcon);
