import React from "react";
import Radium, { StyleRoot } from "radium";

const MainButton = (props) => {
  const style = {
    minWidth: "7rem",
    textAlign: "center",
    padding: "0.5rem",
    margin: "1rem",
    borderRadius: "2rem",
    color: "red",
    backgroundColor: "white",
    border: ".5px solid var(--original-grey)",
    fontFamily: "var(--main-font)",
    fontSize: ".9em",
    boxShadow: "0px 10px 10px 0px rgba(50, 50, 50, 0.2)",

    ":hover": {
      cursor: "pointer",
      color: "white",
      backgroundColor: "var(--p-pink)",
      border: "none",
      outline: "none",
    },
    ":focus": {
      outline: "none",
    },
  };
  return (
    <StyleRoot>
      <button type={props.type} onClick={props.click} style={style}>
        {props.label}
      </button>
    </StyleRoot>
  );
};
export default Radium(MainButton);
