import React, { useState, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

import TopSection from "../../Headers/TopSection/TopSection";
import MainNav from "../../Headers/MainNav/MainNav";
import Background from "../../../assets/images/dashBG.png";
import Error from "../../UI/error";
import Success from "../../UI/success";
import Spinner from "../../UI/Spinner/Spinner";
import MainButton from "../../UI/MainButton/MainButton";

const PassResetForm = (props) => {
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [verifiedUID, setVerifiedUID] = useState(false);

	const { jwt } = useParams();
	const sendJWTVerificationRequest = async (token) => {
		try {
			const res = await axios({
				method: "POST",
				url: `/api/reset/verify`,
				data: { token },
			});
			res.status == 200 ? setVerifiedUID(res.data.UID) : setError(true);
		} catch (err) {
			setError(true);
			console.log(err);
		}
	};
	useEffect(() => {
		if (jwt) {
			sendJWTVerificationRequest(jwt);
		} else {
			setError(true);
		}
	}, []);
	const schema = Yup.object({
		Password: Yup.string()
			.required("Required")
			.min(6, "Password must be at least 6 characters")
			.max(15, "Password must be 15 or less characters"),
		"Confirm Password": Yup.string()
			.required("Required")
			.oneOf([Yup.ref("Password")], "Passwords do not match"),
	});
	const formik = useFormik({
		initialValues: { Password: "", "Confirm Password": "" },
		onSubmit: async (values) => {
			console.log(values);
			try {
				const res = await axios({
					method: "PATCH",
					url: `/api/reset/update`,
					data: { uid: verifiedUID, ...values },
				});
				console.log(res.status);
				res.status == 200 ? setSuccess(true) : setError(true);
			} catch (err) {
				setError(true);
			}
		},
		validationSchema: schema,
	});

	const navHandler = () => {
		props.history.push("/MarketPlace");
	};
	const style = {
		container: {
			position: "relative",
			top: "8rem",
			alignItems: "flex-start",
			width: "500px",
		},
		background: {
			position: "absolute",
			minWidth: "100vw",
			minHeight: "100vh",
			maxWidth: "100vw",
			maxHeight: "100vh",
			overflow: "hidden",
			backgroundImage: `url(${Background})`,
			backgroundSize: "60%",
			backgroundPosition: "bottom right",
			backgroundRepeat: "no-repeat",
			zIndex: "-99",
		},
	};
	return (
		<StyleRoot>
			<React.Fragment>
				<TopSection logo="Peicst" />
				<MainNav click={navHandler} label="Browse Trades" />
				{verifiedUID && <div style={style.background} />}
				<div className="flexCol" style={style.container}>
					{error ? (
						<Error />
					) : success ? (
						<Success />
					) : verifiedUID ? (
						<>
							<h3
								className="headings"
								style={{ fontSize: "1.5em", textAlign: "left" }}>
								Enter a <span className="spanRed">new password </span>
								below
							</h3>
							<br />
							{Object.keys(formik.initialValues).map((i, idx) => {
								return (
									<>
										<label
											key={`RESETPASSLABEL${idx}`}
											htmlFor="Password"
											className="modalTitle">
											{i}:
										</label>
										<input
											key={`RESETPASSINPUT${idx}`}
											type="password"
											name={i}
											className={
												formik.touched[i] && formik.errors[i]
													? "redBorderFormInput"
													: ["formInput", "dark"].join(" ")
											}
											autoComplete="off"
											{...formik.getFieldProps(i)}
										/>
										{formik.touched[i] && formik.errors[i] ? (
											<small
												key={`RESETPASSERRMSG${idx}`}
												style={{ marginRight: "50%" }}
												className="red">
												{formik.errors[i]}
											</small>
										) : null}
									</>
								);
							})}

							<MainButton
								type="submit"
								click={formik.handleSubmit}
								label="Send"
							/>
						</>
					) : (
						<div className="flexRow" style={{ width: "100vw" }}>
							<Spinner />
						</div>
					)}
				</div>
			</React.Fragment>
		</StyleRoot>
	);
};

export default Radium(PassResetForm);
