import React, { useState, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import useRequest from "../../../hooks/useRequest";

import TopSection from "../../Headers/TopSection/TopSection";
import MainNav from "../../Headers/MainNav/MainNav";
import Background from "../../../assets/images/dashBG.png";

import NotificationContainer from "../NotificationContainer/notificationContainer";
import NotificationItem from "../NotificationItem/notificationItem";

import ExpandedPeicst from "../../ExpandedPeicst/parent";
import ChatAPI from "../../chat/API/chatAPIController";
import ChatWindow from "../../chat/chatWindow";
import Modal from "../../UI/Modal/Modal";

import Spinner from "../../UI/Spinner/Spinner";
import Error from "../../UI/error";

const NotificationPage = (props) => {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [modal, setmodal] = useState(false);

  const [chatViewer, setChatViewer] = useState(false);
  const [messages, setmessages] = useState([]);
  const [offerRef, setOfferRef] = useState("");

  const [postViewer, setPostViewer] = useState(null);

  const { userID, reqSender } = useRequest();

  console.log(chatViewer, messages, offerRef, postViewer);
  const refreshData = () => {
    if (userID) {
      reqSender(
        `https://peicst.com/api/users/notifications/${userID}`,
        "GET"
      ).then((res) => {
        setData(res.data.user.reverse());
      });
    }
  };
  useEffect(() => {
    refreshData();
  }, [userID]);

  const notificationViewer = (id, type) => {
    if (type === "Message") {
      setChatViewer(id);
      return;
    } else if (type === "Negotiation") {
      setPostViewer(id);
      setmodal(true);
    }
  };

  const cancelChat = () => {
    setChatViewer(false);
  };

  const modalCancel = () => {
    setmodal(false);
    setPostViewer(null);
    window.location.reload();
  };

  const navHandler = () => {
    props.history.push("/MarketPlace");
  };

  const refresh = () => {
    window.location.reload();
  };

  const showLoader = () => {
    setLoading(!loading);
  };
  const style = {
    page: {
      width: "100vw",
      height: "100vh",
      minWidth: "100vw",
      minHeight: "100vh",
      overflow: "hidden",
      backgroundImage: chatViewer ? null : `url(${Background})`,
      backgroundSize: "60%",
      backgroundPosition: "bottom right",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  };
  if (error) {
    return <Error />;
  } else if (loading) {
    return <Spinner />;
  } else {
    return (
      <StyleRoot>
        <TopSection logo="Peicst" />
        <MainNav click={navHandler} label="Browse Trades" />
        <div style={style.page}>
          {postViewer || chatViewer ? null : (
            <React.Fragment>
              <h2
                style={{
                  fontSize: "3rem",
                  marginTop: "8rem",
                  "@media (max-width: 770px)": {
                    fontSize: "2.7em",
                  },
                }}
                className="headings">
                Notifications
              </h2>
              <NotificationContainer noItems={!data.length && true}>
                {data.map((i, index) => {
                  return (
                    <NotificationItem
                      viewer={notificationViewer}
                      key={`notificationItem${index * 345}`}
                      userName={i.userName}
                      type={i.type}
                      body={i.body}
                      timeStamp={i.timeStamp}
                      id={i.itemId}
                      notiId={i.id}
                      refresh={refresh}
                      loading={showLoader}
                    />
                  );
                })}
              </NotificationContainer>
            </React.Fragment>
          )}
          {postViewer && (
            <Modal click={modalCancel} show={modal}>
              <ExpandedPeicst id={postViewer} goBack={modalCancel} />
            </Modal>
          )}
          {chatViewer && <ChatAPI cancel={cancelChat} id={chatViewer} />}
        </div>
      </StyleRoot>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.isAuth,
    userId: state.userId,
  };
};

export default withRouter(connect(mapStateToProps)(Radium(NotificationPage)));
