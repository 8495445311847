import React from "react";
import Backdrop from "../BackDrop/BackDrop";
import Radium, { StyleRoot } from "radium";

const Modal = (props) => {
  const style = {
    overflowY: "scroll",
    boxShadow: "0 2px 10px 5px rgba(0, 0, 0, 0.3)",
    fontFamily: "var(--sub-font)",
    borderRadius: ".5rem",
    textAlign: "center",
    position: "fixed",
    zIndex: "500",
    backgroundColor: "white",
    width: props.width ? props.width : "65vw",
    height: props.height ? props.height : "100vh",
    border: "1px solid #ccc",
    padding: "0",
    left: props.left ? props.left : "17.5%",
    top: props.top ? props.top : "0",
    boxSizing: "border-box",
    transition: "all 0.3s ease-out",
    transform: props.show ? "translateY(0)" : "translateY(-100vh)",
    opacity: props.show ? "1" : "0",
    "@media (max-width: 1110px)": {
      minWidth: "80%",
      maxWidth: "80%",
      left: "10%",
    },
    "@media (max-width: 770px)": {
      minWidth: "100%",
      maxWidth: "100%",
      minHeight: "100%",
      maxHeight: "100%",
      left: "0",
      top: "0",
      borderRadius: "0",
    },
  };
  return (
    <StyleRoot>
      <React.Fragment>
        <Backdrop show={props.show} click={props.click} />
        <div style={style}>{props.children}</div>
      </React.Fragment>
    </StyleRoot>
  );
};

export default Radium(Modal);
