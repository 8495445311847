import React, { useState, useEffect } from "react";
import Radium, { StyleRoot } from "radium";
import MainButton from "../../UI/MainButton/MainButton";
import { withRouter } from "react-router-dom";
import { HashLink as NavLink } from "react-router-hash-link";
import { connect } from "react-redux";
import BlackBurger from "../../../assets/images/blackBurger.png";
import Bin from "../../../assets/images/closeNav.png";
import Facebook from "../../../assets/images/faceBook.png";
import Twitter from "../../../assets/images/twitter.png";
import Insta from "../../../assets/images/instaGram.png";

const MainNav = (props) => {
  const [showMenu, setshowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setshowMenu(false);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setshowMenu(false);
      });
    };
  }, []);

  const menuHandler = () => {
    setshowMenu(!showMenu);
  };

  const navButts = [
    "Home",
    "How it Works",
    "About us",
    "FAQs",
    "Donate",
    "Contact",
  ];
  const socials = [Facebook, Twitter, Insta];
  const style = {
    menu: {
      position: "fixed",
      top: "6vh",
      left: "0",
      zIndex: "99",
      margin: "0 auto",
      background: "var(--p-grey)",
      minWidth: "100vw",
      maxHeight: "6vh",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      "@media (max-width: 770px)": {
        overflowY: "scroll",
        top: "10vh",
        left: "0",
        background: "var(--p-grey)",
        minWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "100vh",
        display: showMenu ? "flex" : "none",
        flexFlow: "column",
        justifyContent: "flex-start",
      },
    },
    burger: {
      position: "fixed",
      top: "12vh",
      left: "5vw",
      zIndex: "500",
      margin: "auto",
      width: "30px",
      ":hover": { cursor: "pointer" },
      "@media (min-width: 770px)": {
        display: "none",
      },
    },
    notification: {
      position: "absolute",
      top: "0",
      right: "0",
      zIndex: "1000",
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      backgroundColor: "red",
      "@media (max-width: 770px)": {
        top: "0",
        right: "0",
      },
    },
  };
  return (
    <StyleRoot>
      <React.Fragment>
        <img
          style={style.burger}
          src={showMenu ? Bin : BlackBurger}
          onClick={menuHandler}
          alt="navigation"
        />

        <div style={style.menu}>
          <MainButton click={props.click} label={props.label} />
          {navButts.map((btn) => {
            return (
              <NavLink
                to={`Landing#${btn}`}
                className={
                  props.location.hash === `#${btn}` ? "active" : "NavButt"
                }
                key={btn}
                onClick={menuHandler}>
                {btn}
              </NavLink>
            );
          })}

          {props.isAuth ? (
            <React.Fragment>
              <NavLink
                style={{ position: "relative" }}
                onClick={menuHandler}
                to="MyDash"
                className={
                  props.location.pathname === "/MyDash" ? "active" : "NavButt"
                }>
                My Dashboard
              </NavLink>
              <NavLink
                style={{ position: "relative" }}
                onClick={menuHandler}
                to="Notification"
                className={
                  props.location.pathname === "/Notification"
                    ? "active"
                    : "NavButt"
                }>
                Notifications
                {props.inbox > 0 ? <div style={style.notification} /> : null}
              </NavLink>
            </React.Fragment>
          ) : null}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "6rem",
            }}>
            {socials.map((i, index) => {
              return (
                <img
                  style={{ width: "25px" }}
                  key={i + index}
                  src={i}
                  alt="Socials"
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    </StyleRoot>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuth: state.isAuth,
    inbox: state.inbox,
  };
};
export default withRouter(connect(mapStateToProps)(Radium(MainNav)));
